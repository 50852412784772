import React, { useContext, useEffect, useState } from "react";
import { LanguageContext } from "../../contexts/LanguageContext";

const LanguageSwitcher = () => {
  const { language, changeLanguage } = useContext(LanguageContext);
  const [selectedLanguage, setSelectedLanguage] = useState(language);

  useEffect(() => {
    // Retrieve language preference from localStorage
    const storedLanguage = localStorage.getItem("preferredLanguage");
    if (storedLanguage) {
      setSelectedLanguage(storedLanguage);
      changeLanguage(storedLanguage);
    }
  }, [changeLanguage]);

  const handleLanguageChange = (e) => {
    const selectedLanguage = e.target.value;
    setSelectedLanguage(selectedLanguage);
    changeLanguage(selectedLanguage);
    // Save language preference to localStorage
    localStorage.setItem("preferredLanguage", selectedLanguage);
  };

  return (
    <select
      className="font-control bg-dark"
      value={selectedLanguage}
      onChange={handleLanguageChange}
    >
      <option value="en">English</option>
      <option value="hi">Hindi</option>
      <option value="ta">Tamil</option>
      <option value="te">Telegu</option>
      <option value="kn">Kannada</option>
      <option value="ml">Malayalam</option>
      <option value="or">Oriya</option>
      <option value="bn">Bengali</option>
      <option value="gu">Gujarati</option>
    </select>
  );
};

export default LanguageSwitcher;
