import React from "react";
import IndiaAIFestivalCTA from "./IndiaAIFestivalCTA";

const IndiaAIFestivalBanner = (props) => {
  const { year } = props;
  return (
    <div className="d-flex align-items-center flex-column">
      <img
        src={`images/india-ai-impact-festival-${year}-banner.jpg`}
        className="w-100"
      />
      <IndiaAIFestivalCTA year={year} />
    </div>
  );
};

export default IndiaAIFestivalBanner;
