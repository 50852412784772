import React from "react";
import { connect } from "react-redux";
import IntelLogo from "../../static/img/Intel.png";
import KMVLogo from "../../static/img/kmv.png";
import AIFCLogo from "../../static/img/aifclogo.svg";
import styled from "styled-components";

const Logo = styled.img`
  width: auto;
  max-width: 100%;
  height: auto !important;
  max-height: 60px;
`;

class Header extends React.Component {
  render() {
    return (
      <React.Fragment>
        <div
          className="position-fixed w-100 header-wrapper"
          style={{ top: "0px" }}
        >
          <div className="header-container">
            <div className="row header-logos-container content-container py-md-2">
              <div className="col-4 header-col d-flex align-items-center justify-content-start">
                <Logo className="header-logo" src={KMVLogo} alt="KMVLogo" />
              </div>
              <div className="col-4 header-col d-flex align-items-center justify-content-center">
                <Logo
                  className="header-logo aisc-logo"
                  src={AIFCLogo}
                  alt="AI Facilitator Community"
                />
              </div>
              <div className="col-4 header-col d-flex align-items-center justify-content-end">
                <Logo
                  className="header-logo intel-logo p-md-2"
                  src={IntelLogo}
                  alt="IntelLogo"
                />
              </div>
            </div>
          </div>

          {!this.props.hideSubHeader && (
            <header className="header">
              <nav
                className={
                  "navbar navbar-expand-lg p-0 " +
                  (this.props.bgColor && this.props.bgColor === "white"
                    ? "custom-nav white-bg"
                    : "")
                }
              >
                <div className="header-links-container content-container">
                  <a href="/" className="header-brand text-uppercase">
                    AI Facilitator Community
                  </a>
                  <div className="header-links">
                    <a href="/about" className="header-link">
                      About
                    </a>
                    <div className="dropdown">
                      <a
                        href="#"
                        className="header-link"
                        id="learningResourcesDropdownMenu"
                        data-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="false"
                      >
                        Learning Resources
                      </a>
                      <div
                        className="dropdown-menu dropdown-menu-lg-right"
                        aria-labelledby="learningResourcesDropdownMenu"
                      >
                        <a
                          href="/learningResources#class-6-8"
                          className="dropdown-item"
                        >
                          Grade 6-8
                        </a>
                        <a
                          href="/learningResources#class-9"
                          className="dropdown-item"
                        >
                          Grade 9
                        </a>
                        <a
                          href="/learningResources#class-10"
                          className="dropdown-item"
                        >
                          Grade 10
                        </a>
                        <a
                          href="/learningResources#handbook"
                          className="dropdown-item"
                        >
                          Handbook
                        </a>
                        <a
                          href="/learningResources#sample-papers"
                          className="dropdown-item"
                        >
                          Sample Papers
                        </a>
                        <a
                          href="/learningResources#question-banks"
                          className="dropdown-item"
                        >
                          Question Bank
                        </a>
                      </div>
                    </div>
                    <a href="/exemplar-blogs" className="header-link">
                      Exemplar Blogs
                    </a>
                    <li className="dropdown">
                      <a
                        href="#"
                        className="header-link"
                        id="indiaAIDropdown"
                        data-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="false"
                      >
                        Impact Festival
                      </a>
                      <div
                        className="dropdown-menu"
                        aria-labelledby="indiaAIDropdown"
                      >
                        <a
                          className="dropdown-item"
                          href="/india-ai-impact-festival-2024"
                        >
                          2024
                        </a>
                        {/* <a
                          className="dropdown-item"
                          href="/india-ai-impact-festival-2025"
                        >
                          2025
                        </a> */}
                      </div>
                    </li>
                    <div className="dropdown">
                      <a
                        href="#"
                        className="header-link"
                        id="lessonPlansDropdownMenu"
                        data-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="false"
                      >
                        AIoT Integration
                      </a>
                      <div
                        className="dropdown-menu dropdown-menu-lg-right"
                        aria-labelledby="lessonPlansDropdownMenu"
                      >
                        <a
                          href="/lesson-plans#grade-vi"
                          className="dropdown-item"
                        >
                          Grade VI
                        </a>
                        <a
                          href="/lesson-plans#grade-vii"
                          className="dropdown-item"
                        >
                          Grade VII
                        </a>
                        <a
                          href="/lesson-plans#grade-viii"
                          className="dropdown-item"
                        >
                          Grade VIII
                        </a>
                        <a
                          href="/lesson-plans#grade-ix"
                          className="dropdown-item"
                        >
                          Grade IX
                        </a>
                        <a
                          href="/lesson-plans#grade-x"
                          className="dropdown-item"
                        >
                          Grade X
                        </a>
                      </div>
                    </div>
                    <div>
                      <a
                        href="https://ai-for-all.in"
                        className="header-link"
                        target="blank"
                      >
                        AI For All
                      </a>
                    </div>
                    <a href="/login" className="header-link">
                      Login
                    </a>
                    <a href="/signup" className="header-link sign-up">
                      Sign up
                    </a>
                  </div>
                </div>
              </nav>
            </header>
          )}
        </div>
      </React.Fragment>
    );
  }
}

export default connect((state) => ({}))(Header);
