import React, { useState, useEffect } from "react";
import "./BlinkingNode.css";

const BlinkingNode = ({ children }) => {
  const [isVisible, setIsVisible] = useState(true);

  useEffect(() => {
    // Toggle visibility every 1 second
    const interval = setInterval(() => {
      setIsVisible((prevVisible) => !prevVisible);
    }, 1000);

    // Cleanup interval on component unmount
    return () => clearInterval(interval);
  }, []);

  return <div className={isVisible ? "blink" : ""}>{children}</div>;
};

export default BlinkingNode;
