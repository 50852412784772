import React from "react";
import SecondHeader from "../components/Header/secondheader";
import Header from "../components/Header/header";
import FooterSection from "../components/Footer";

class Faq extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const listItem = (id, title, description, lastItem, accordionName) => {
      const additionalClass = lastItem ? "last-accordion-section " : null;
      return (
        <div
          className={`c-pointer  ${additionalClass} card border-0 border-bottom-solid  rounded-0`}
          key={id}
          id={`accordian${id}`}
        >
          <div
            className="card-header mb-0 faq-list-hover p-0 border-0 bg-white  hover-text-theme-primary"
            role="tab"
            id="heading1"
          >
            <div
              className="collapsed text-decoration-none py-4 border-bottom  text-theme-black-pearl hover-text-theme-primary d-block py-8"
              data-toggle="collapse"
              data-parent={`#accordion${accordionName}`}
              href={"#collapse" + id}
              aria-expanded="false"
              aria-controls={"collapse" + id}
            >
              <h5 className="mb-0 plus-icon pl-3" style={{ color: "#7288a2" }}>
                {title}
              </h5>
            </div>
          </div>
          <div
            id={"collapse" + id}
            className="collapse"
            role="tabpanel"
            aria-labelledby={"heading" + id}
            data-parent={`#accordion${accordionName}`}
          >
            <div className="card-body lead pt-3 text-dark border-bottom">
              {description}
            </div>
          </div>
        </div>
      );
    };

    const aboutPlatform = [
      {
        title: "What is the India AI Impact Festival?",
        description: `The India AI Impact Festival is an initiative aligned with the National Education Policy (NEP) 2020. It aims to promote AI
readiness among students and educators, fostering innovation, creativity, and problem-solving skills through the use of AI
technology, social skills, and tinkering skills in an inclusive manner.`,
      },
      {
        title: "Is the India AI Impact Festival open to all?",
        description:
          "Yes, the festival aims to be inclusive and welcomes participation from students, educators, innovators, and AI enthusiasts across India who are interested in leveraging AI technology for social impact and skill development.",
      },
      {
        title:
          "Are there any costs involved for participating in the festival?",
        description:
          "No. There are no costs involved in registering or making submissions for the India AI Impact Festival.",
      },
      {
        title: "What is the theme of the India AI Impact Contest?",
        description:
          "The theme of the India AI Impact Contest is 'AI with a purpose.' And the entries can be aligned with the United Nations Sustainable Development Goals (SDGs).",
      },
      {
        title:
          "What are the types of categories in the India AI Impact Contest?",
        description:
          " The categories are Impact Creators (for students), Impact Shapers (for educators), and Impact Nurturers (for educational institutions).",
      },
      {
        title:
          "What are the eligibility criteria for each category in the India AI Impact Contest?",
        description: (
          <>
            <ol style={{ listStyle: "upper-alpha" }}>
              <li>
                Impact Creators: Open to school and college students aged 11 to
                25. Sub-categories include:
              </li>
              <ul style={{ listStyleType: "disc" }} className="ml-4">
                <li>Students from grades 6 to 8</li>
                <li>Students from grades 9 to 12</li>
                <li>Youth in the 18 to 25 years age-group</li>
              </ul>

              <li>
                Impact Shapers: Open to school and college educators, including
                teachers, professors, and educators from universities and
                colleges across India, regardless of their teaching subject.
              </li>
              <li>
                Impact Nurturers: Open to academic/technical institutions such
                as schools, colleges, vocational, and skilling institutions,
                aimed at fostering participation and innovation within their
                respective institutions.
              </li>
            </ol>
          </>
        ),
      },
      {
        title: "What will be the language used for the submissions?",
        description: "Entries can be submitted in English and Hindi. ",
      },
      {
        title:
          "What are the submission requirements for all categories in the India AI Impact Contest?",
        description: (
          <>
            <ol style={{ listStyle: "upper-alpha" }}>
              <li>Impact Creators (Students)</li>
              <ul style={{ listStyleType: "disc" }} className="ml-4">
                <li>
                  Submit a project synopsis (PDF) detailing the problem, AI/AIoT
                  tools used,
                </li>
                <li>A 2-minute project video to support the synopsis</li>
              </ul>
              <li>Impact Shapers (Educators)</li>
              <ul style={{ listStyleType: "disc" }} className="ml-4">
                <li>Provide a poster on innovative teaching practices</li>
                <li>A 2-minute video on their AI/AIoT journey</li>
              </ul>
              <li>Impact Nurturers (Educational Institutions)</li>
              <ul style={{ listStyleType: "disc" }} className="ml-4">
                <li>Submit a poster showcasing past AI/AIoT practices</li>
                <li>A 2-minute video explaining their journey and impact.</li>
              </ul>
            </ol>
          </>
        ),
      },
      {
        title:
          "What rewards await participants in the India AI Impact Contest?",
        description:
          "Participants stand to receive attractive prizes, co-branded winner and participation certificates, mentorship opportunities, certificates of recognition for the special awards category, and visibility for their winning solutions on national and global platforms.",
      },
      {
        title:
          "How will the evaluation process be conducted in the India AI Impact Contest?",
        description: (
          <>
            The evaluation process comprises of two levels:
            <ol style={{ listStyle: "upper-alpha" }}>
              <li>
                Level 1: An independent third-party evaluation agency will
                conduct the initial evaluation to shortlist the top 10 entries
                in each category.
              </li>
              <ul style={{ listStyleType: "disc" }} className="ml-4">
                <li>
                  The top 10 projects will receive mentoring from experts, both
                  online and offline, to refine their projects and
                  presentations.
                </li>
              </ul>

              <li>
                Level 2: Shortlisted entries from Level 1 will be further
                evaluated by senior representatives from collaborators,
                academia, and industry experts to identify the top 3 in each
                category.
              </li>
            </ol>
            Evaluation will be based on predefined criteria, including technical
            merit, social and sustainable impact, innovation, and presentation.
          </>
        ),
      },
      {
        title: "What are the evaluation parameters?",
        description: (
          <>
            The evaluation parameters for Impact Creators can be found{" "}
            <a
              className="text-primary font-weight-bold"
              href="/assets/pdf/evaluation-impact-creators.pdf"
            >
              here
            </a>
            . The evaluation parameters for Impact Shapers and Nurturers can be
            found{" "}
            <a
              className="text-primary font-weight-bold"
              href="/assets/pdf/evaluation-impact-shapers-nurturers.pdf"
            >
              here
            </a>
            .
          </>
        ),
      },
      {
        title:
          "Can an incomplete entry submitted for the Festival, continue to be considered for evaluation?",
        description:
          "No. As per the competition guideline incomplete entries will be disqualified. The decision of the evaluation team is final. ",
      },
      {
        title:
          "Who are the key collaborators for the India AI Impact Festival 2024? ",
        description: (
          <>
            The festival is a collaborative effort between various
            organizations, including the
            <ul style={{ listStyleType: "disc" }} className="ml-4">
              <li>
                Central Board of Secondary Education (CBSE) under the Ministry
                of Education
              </li>
              <li>US Embassy in India</li>
              <li>Intel India</li>
            </ul>
          </>
        ),
      },
      {
        title:
          "Are employees/ interns employed with any of the collaborators, allowed to participate in any of the categories of the India AI Impact Festival 2024?",
        description:
          "No. No Employees – including contingent workers – working with any of the collaborators cannot compete in any category of the competition. However, relatives/families of employees can participate and compete in the festival.",
      },
      {
        title:
          "Is there any avenue for contesting or appealing the evaluation?",
        description:
          "The evaluation is final and will not be contested anywhere.",
      },
      {
        title:
          "Whom should I contact for any query/ clarification regarding the festival?",
        description: (
          <>
            For any query or clarification, pl share an email at{" "}
            <p style={{ color: "blue" }}>aiimpactfestival@gmail.com</p>or you
            can connect with the India AI Impact Festival helpdesk at
            9058585055.,
          </>
        ),
      },
    ];
    return (
      <React.Fragment>
        {localStorage.getItem("jwtToken") == null ? (
          <Header />
        ) : (
          <SecondHeader />
        )}
        <section className="our-blog-section  gray-light-bg">
          <div className=" blog-container shadow">
            <div className="row user-info-container">
              <div className="col-md-12">
                <h1 className="mar-b-30 text-center">
                  India AI Impact Festival 2024
                </h1>
                <h2 className="mar-b-30 text-center">
                  Frequently Asked Questions
                </h2>
              </div>
            </div>
            <div className="container w-75 pb-3">
              <div
                className="accordion md-accordion mt-6 top-accordian "
                id="accordionAboutPlatform"
                role="tablist"
                aria-multiselectable="true"
              >
                {aboutPlatform.map((item, index) =>
                  listItem(
                    index + 20,
                    item.title,
                    item.description,
                    aboutPlatform.length - 1 === index,
                    "AboutPlatform"
                  )
                )}
              </div>
            </div>
          </div>
        </section>
        <FooterSection noSubscription={true} />
      </React.Fragment>
    );
  }
}

export default Faq;
