import React, { Component } from "react";
import moment from "moment-timezone";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { logoutUser } from "../actions/authAction";
import Footer from "../components/Footer/index";
import SecondHeader from "../components/Header/secondheader";
import Bio from "./Bio";
import EventCounter from "./EventCounter";
import WhatsNew from "./WhatsNew";
import StartLearning from "./StartLearning";
import LatestinAI from "./LatestInAI";
import LatestBlogs from "./LatestBlogs";
import LatestEvent from "./LatestEvent";
import Socials from "./Socials";
import { BlinkingNode } from "../components/shared";
import { isSubmissionOpen } from "../utils/impactFestival";

class Dashboard extends Component {
  constructor(props) {
    super(props);

    this.state = {
      data: [],
      blog: [],
      events: [],
      myDivToFocus: React.createRef(),
      isOpen: true,
    };
  }

  getEvents() {
    const studentId = localStorage.getItem("studentId");
    fetch(
      `${process.env.REACT_APP_API_URL}/scheduled-events?student=${studentId}`
    )
      .then((response) => response.json())
      .then((result) => {
        this.setState({
          events: result.events,
        });
      });
  }

  getBlog() {
    fetch(`${process.env.REACT_APP_API_URL}/blogs`, {
      headers: {
        "x-access-token": localStorage.getItem("jwtToken"),
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    })
      .then((response) => response.json())
      .then((data) => {
        this.setState({ blog: data.blogs });
      });
  }

  getData() {
    const id = localStorage.getItem("studentId");
    const token = localStorage.getItem("jwtToken");
    fetch(`${process.env.REACT_APP_API_URL}/dashboard/` + id, {
      headers: {
        "x-access-token": token,
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    })
      .then((response) => response.json())
      .then(
        (result) => {
          this.setState({
            isLoaded: true,
            data: result.data,
          });
        },
        (error) => {
          this.setState({
            isLoaded: true,
            error,
          });
        }
      );
  }

  startProcess() {
    const id = localStorage.getItem("studentId");
    const token = localStorage.getItem("jwtToken");
    fetch(`${process.env.REACT_APP_API_URL}/startprogress/${id}`, {
      headers: {
        "x-access-token": token,
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    }).then((response) => response.json());
  }

  scrollMore() {}

  componentDidMount() {
    this.getData();
    this.getEvents();
    this.getBlog();
  }

  render() {
    const { data, blog, events } = this.state;
    const currentYear = moment().year();
    const _isSubmissionOpen = isSubmissionOpen(2024);

    return (
      <React.Fragment>
        <SecondHeader />
        <section className="our-blog-section gray-light-bg single-blog-container">
          {_isSubmissionOpen && (
            <div className="d-flex justify-content-center mt-5 mt-md-0">
              <BlinkingNode>
                <a
                  href={`/india-ai-impact-festival-registration-${currentYear}`}
                  className="btn btn-primary"
                >
                  {`Submit your entry for India AI Impact Festival ${currentYear}`}
                </a>
              </BlinkingNode>
            </div>
          )}
          <div className="blog-container container mx-auto shadow rounded">
            <Bio data={data} />
            <EventCounter />
            <WhatsNew />
            <StartLearning />
            <LatestEvent data={events} />
            <LatestinAI data={data} />
            <LatestBlogs data={blog} />
            <Socials />
          </div>
        </section>

        <Footer />
      </React.Fragment>
    );
  }
}

Dashboard.propTypes = {
  logoutUser: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps, { logoutUser })(Dashboard);
