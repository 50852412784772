import React, { Component } from "react";
import Header from "../Header/header";
import SecondHeader from "../Header/secondheader";
import { withRouter, Link, Redirect } from "react-router-dom";
import Footer from "../Footer/index";
import returnIcon from "../../static/img/return.png";

class SingleExemplarBlog extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: null,
      id: props.data,
    };
  }

  componentDidMount() {
    const { location } = this.props;

    fetch(
      `${process.env.REACT_APP_API_URL}/public-blogs${location.pathname.replace(
        /\/exemplar-blog/,
        ""
      )}`,
      {
        headers: {
          "x-access-token": localStorage.getItem("jwtToken"),
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      }
    )
      .then((response) => response.json())
      .then(
        (result) => {
          this.setState({
            isLoaded: true,
            data: result.blogs,
          });
        },
        (error) => {
          this.setState({
            isLoaded: true,
            error,
          });
        }
      );
  }

  render() {
    const { data } = this.state;
    if (data && data.length === 0) {
      return <Redirect to="/404page" />;
    }

    return (
      <React.Fragment>
        {localStorage.getItem("jwtToken") == null ? (
          <Header />
        ) : (
          <SecondHeader />
        )}
        {data &&
          data.map((data, index) => (
            <section
              className="our-blog-section gray-light-bg single-blog-container"
              key={index}
            >
              <div className="blog-container container mx-auto shadow">
                <div className="col-md-12 text-left">
                  <Link to="/exemplar-blogs">
                    <div
                      className="text-carnation go-back-mar"
                      style={{ fontSize: "18px", fontWeight: "bold" }}
                    >
                      <img
                        className="return-icon"
                        src={returnIcon}
                        alt="icon"
                      />
                      <p className="return-label">Go Back</p>
                    </div>
                  </Link>
                  <div className="section-heading text-left">
                    <h3 className="text-carnation text-left">{data.title}</h3>
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-12">
                    <div className="">
                      <div className="col-md-12 mb-4">
                        <div className="single-blog-card card border-0 shadow-sm m-auto">
                          <div className="blogs-tag">
                            {data.tags &&
                              data.tags.slice(0, 3).map((tags, key) => (
                                <span
                                  key={key}
                                  className="mar-r-8 badge badge-pill badge-primary"
                                >
                                  {tags.value}
                                </span>
                              ))}
                          </div>

                          <img
                            src={data.cover}
                            className="card-img-top hero-img-blog position-relative"
                            alt="blog"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="mb-50">
                  <div className="col-md-12 single-blog-content2">
                    <p
                      className="data-block-caption"
                      dangerouslySetInnerHTML={{
                        __html: data.content,
                      }}
                    />
                  </div>
                </div>
                <div className="col-md-12">
                  <hr className="gray-separator"></hr>
                </div>
              </div>
            </section>
          ))}

        <Footer />
      </React.Fragment>
    );
  }
}

export default withRouter(SingleExemplarBlog);
