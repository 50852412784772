import React, { useState, useEffect } from "react";
import Popup from "reactjs-popup";
import { isSubmissionOpen } from "../../utils/impactFestival";

const IndiaAIFestivalRedirectionPopUp = ({ year }) => {
  const [isOpen, setOpen] = useState(true);

  useEffect(() => {
    if (isOpen) {
      document.body.classList.add("body-no-scroll");
    } else {
      document.body.classList.remove("body-no-scroll");
    }

    return () => {
      document.body.classList.remove("body-no-scroll");
    };
  }, [isOpen]);

  return (
    isSubmissionOpen(year) &&
    isOpen && (
      <Popup
        trigger={<button style={{ display: "none" }}></button>}
        position="top center"
        open={isOpen}
        closeOnDocumentClick={false}
        overlayStyle={{
          backgroundColor: "rgba(0, 0, 0, 0.80)",
          overflow: "hidden",
        }}
      >
        <div
          className="d-flex flex-column align-items-center p-4 rounded-3 shadow-lg"
          style={{ backgroundColor: "white" }}
        >
          <h2 className="mt-3">India AI Impact Festival {year}</h2>
          <p className="text-center mb-4">
            <strong>Hurry! Registrations close soon.</strong>
            <br />
            Join India’s largest AI innovation challenge for students and
            facilitators.
          </p>

          <div className="w-100 mb-3">
            <h5 className="txt txt-primary text-center">
              <strong>Are you a Student or Facilitator?</strong>
            </h5>
            <p className="text-muted text-center small">
              Facilitators: Stay here to register.
              <br />
              Students: Visit the AI Student platform.
            </p>
          </div>

          <div className="d-flex justify-content-center w-100 gap-3 mt-3">
            <button
              onClick={() => setOpen(false)}
              className="btn btn-primary rounded-3 mx-2 px-4 py-2 text-white"
              style={{ minWidth: "120px" }}
            >
              I’m a Facilitator
            </button>
            <button
              onClick={() =>
                (window.location.href = `https://aistudent.community`)
              }
              className="btn btn-blue rounded-3 mx-2 px-4 py-2 text-white"
              style={{ minWidth: "120px" }}
            >
              I’m a Student
            </button>
          </div>
        </div>
      </Popup>
    )
  );
};

export default IndiaAIFestivalRedirectionPopUp;
