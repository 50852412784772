import React from "react";
import Header from "../components/Header/header";
import SecondHeader from "../components/Header/secondheader";
import { useTranslation } from "react-i18next";
import Footer from "../components/Footer/index";
import styled from "styled-components";
import { SUBMISSION_END_TIMES } from "../utils/impactFestival";
import { formatDateToDDMMMYYYY } from "../utils";
import {
  IndiaAIFestivalComponents,
  IndiaAIFestivalTarget,
  IndiaAIFestivalTimeline,
  IndiaAIFestivalSpecialAwards,
  IndiaAIFestivalHowToParticipate,
  IndiaAIFestivalBanner,
  IndiaAIFestivalContact,
  IndiaAIFestivalMainAwards,
  IndiaAIFestivalSubmissionGuidelines,
  IndiaAIFestivalGuidelines,
  IndiaAIFestivalProjectShowcase,
  IndiaAIFestivalDeadlineNotice,
} from "../components/IndiaAIFestival";
import { BlinkingNode, LanguageSwitcher } from "../components/shared";

const Section = styled.div`
  margin-top: 35px;

  @media (min-width: 992px) {
    margin-top: 75px;
  }
`;

const Section2 = styled.div`
  margin-top: 15px;

  @media (min-width: 992px) {
    margin-top: 30px;
  }
`;

const IndiaAIFestivalPage = () => {
  const { t } = useTranslation();

  const year = 2024;
  const config = {
    nationalAwards: {
      categories: [
        {
          title: t("impact_creators_title"),
          items: [
            { description: t("impact_creators_list_item_1") },
            { description: t("impact_creators_list_item_2") },
            { description: t("impact_creators_list_item_3") },
          ],
          imageUrl: "/images/impact-creators.png",
        },
        {
          title: t("impact_shapers_title"),
          items: [{ description: t("impact_shapers_list_item_1") }],
          imageUrl: "/images/impact-shapers.png",
        },
        {
          title: t("impact_nurturers_title"),
          items: [{ description: t("impact_nurturers_list_item_1") }],
          imageUrl: "/images/impact-nurturers.png",
        },
      ],
    },
    specialAwards: {
      categories: [
        {
          title: t("nari_shakti_title"),
          items: [{ description: t("nari_shakti_list_item") }],
          imageUrl: "/images/inclusivity.png",
        },
        {
          title: t("accessibility_title"),
          items: [{ description: t("accessibility_list_item") }],
          imageUrl: "/images/accessibility.png",
        },
        {
          title: t("diversity_title"),
          items: [
            { description: t("diversity_list_item_1") },
            { description: t("diversity_list_item_2") },
          ],
          imageUrl: "/images/technology.png",
        },
      ],
    },
  };

  return (
    <>
      {localStorage.getItem("jwtToken") == null ? (
        <Header hideSubHeader />
      ) : (
        <SecondHeader hideSubHeader />
      )}

      <div className="festival-section gray-light-bg">
        {
          <div class="d-flex justify-content-center w-100">
            <BlinkingNode>
              <IndiaAIFestivalDeadlineNotice
                deadline={formatDateToDDMMMYYYY(SUBMISSION_END_TIMES[year])}
                year={year}
              />
            </BlinkingNode>
          </div>
        }
        <div className="container d-flex justify-content-end">
          <LanguageSwitcher />
        </div>
        <div className="blog-container shadow px-0 pt-0 mt-0">
          <div className="mar-t-15">
            <IndiaAIFestivalBanner year={year} />
          </div>

          <div className="container">
            <Section2>
              <IndiaAIFestivalProjectShowcase year={year} />
            </Section2>
          </div>

          <Section>
            <IndiaAIFestivalTarget />
          </Section>

          <div className="container">
            <div className="px-md-2 px-xl-5 mx-auto">
              <Section className="row justify-content-center">
                <div className="col-md-5">
                  <IndiaAIFestivalMainAwards
                    title={t("national_awards_title")}
                    data={config.nationalAwards}
                  />
                </div>

                <div className="offset-md-1 col-md-5 mt-4 mt-md-0">
                  <IndiaAIFestivalSpecialAwards
                    title={t("special_awards_title")}
                    data={config.specialAwards}
                  />
                </div>
              </Section>
            </div>

            <Section className="px-md-2 px-xl-5 mx-auto">
              <IndiaAIFestivalHowToParticipate year={year} />
            </Section>

            <Section className="px-md-2 px-xl-5 mx-auto">
              <IndiaAIFestivalSubmissionGuidelines />
            </Section>

            <Section className="px-md-2 px-xl-5 mx-auto">
              <IndiaAIFestivalComponents />
            </Section>

            <Section className="px-md-2 px-xl-5 mx-auto">
              <IndiaAIFestivalTimeline year={year} />
            </Section>

            <Section className="px-md-2 px-xl-5 mx-auto">
              <IndiaAIFestivalGuidelines year={year} />
            </Section>

            <Section className="px-md-2 px-xl-5 mx-auto">
              <IndiaAIFestivalContact year={year} />
            </Section>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default IndiaAIFestivalPage;
