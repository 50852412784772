import React, { Component } from "react";
import SecondHeader from "../../components/Header/secondheader";
import Footer from "../../components/Footer/index";
import Modal from "react-modal";
import "../../index.css";
import PostNew from "./PostNew";
import { FaWindowClose } from "react-icons/fa";
import { Link } from "react-router-dom";

class PostList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      posts: [],
      id: props.data,
      showModal: false,
      categories: [],
      selectedCategory: {},
    };
    this.handleOpenModal = this.handleOpenModal.bind(this);
    this.handleCloseModal = this.handleCloseModal.bind(this);
  }

  handleOpenModal() {
    this.setState({ showModal: true });
  }

  handleCloseModal() {
    this.setState({ showModal: false });
  }

  postData = () => {
    const { location } = this.props;
    fetch(
      `${
        process.env.REACT_APP_API_URL
      }/forums/categories${location.pathname.replace(/\/post/, "")}/posts`,
      {
        headers: {
          "x-access-token": localStorage.getItem("jwtToken"),
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      }
    )
      .then((response) => response.json())
      .then(
        (result) => {
          this.setState({
            isLoaded: true,
            posts: result.posts,
            selectedCategory: result.category,
            id: result.category.name,
          });
        },
        (error) => {
          this.setState({
            isLoaded: true,
            error,
          });
        }
      );
  };
  componentDidMount() {
    this.categoryData();
    this.postData();
  }
  categoryData = () => {
    fetch(`${process.env.REACT_APP_API_URL}/forums/categories`, {
      headers: {
        "x-access-token": localStorage.getItem("jwtToken"),
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    })
      .then((response) => response.json())
      .then(
        (result) => {
          this.setState({
            isLoaded: true,
            categories: result.categories,
          });
        },
        (error) => {
          this.setState({
            isLoaded: true,
            error,
          });
        }
      );
  };

  render() {
    return (
      <div>
        <SecondHeader />
        <div className="limiter shadow mt-30 blog-container">
          <div className="row">
            <div className=" col-md-8">
              <div className="section-heading mb-5">
                <h2>Topics</h2>
              </div>
            </div>
            <div className="new-post col-md-4">
              <button className="btn-blue " onClick={this.handleOpenModal}>
                New Post
              </button>
              <Modal
                aria-labelledby="modal-title"
                aria-describedby="modal-description"
                isOpen={this.state.showModal}
                contentLabel="Minimal Modal Example"
                onRequestClose={this.handleCloseModal}
                style={{ overlay: { zIndex: 1000 } }}
              >
                <div className="modal-btn">
                  <FaWindowClose
                    onClick={this.handleCloseModal}
                    color="#162b4d"
                    size={30}
                  />
                </div>
                <PostNew />
                {/* <div className='modal-btn' >
                <button className="btn-blue modal-btn" onClick={this.handleCloseModal}>Close Modal</button>
                </div> */}
              </Modal>
            </div>
          </div>

          <section className="p-t-50">
            <div className="container">
              <div className="card">
                <div className="row p-t-20 p-l-20">
                  <div className="all-select-categories">
                    <select
                      className="select-categories"
                      value={this.state.selectedCategory.id}
                      defaultValue={this.state.selectedCategory.id}
                      onChange={(e) =>
                        (window.location.href = `/post/${e.target.value}`)
                      }
                    >
                      {/* <option onChange={()=>window.location.href='/forum'}>All</option> */}
                      {this.state.categories.map((categories, key) => (
                        <option key={key} value={categories.id}>
                          {categories.name}
                        </option>
                      ))}
                    </select>
                  </div>
                  <Link to="/forum">
                    <button className="category-btn">Categories</button>
                  </Link>
                </div>
                {this.state.posts.length === 0 ? (
                  <h5 className="post-heading">
                    There are no comments on this post, add your first post!
                  </h5>
                ) : (
                  <div className="body">
                    <h4 className="category-heading">
                      {this.state.selectedCategory.name}
                    </h4>
                    <div className="table-responsive">
                      <table className="table table-hover m-b-0">
                        <thead>
                          <tr>
                            <th>Sl. No.</th>
                            <th>Title</th>
                            <th>View</th>
                            <th>Comments</th>
                          </tr>
                        </thead>
                        <tbody>
                          {this.state.posts.map((posts, index) => (
                            <tr className="category-table-row" key={index}>
                              <td>{index + 1}</td>
                              {/* <td><span className="list-icon"><img className="rounded" src="assets/images/xs/avatar1.jpg" alt=""></span></td> */}
                              <td
                                onClick={() =>
                                  this.props.history.push(
                                    `/postdetails/${posts.id}`
                                  )
                                }
                              >
                                {posts.title}
                              </td>
                              <td>{posts.views}</td>
                              <td>{posts.commentCount}</td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </section>
        </div>

        <Footer />
      </div>
    );
  }
}

export default PostList;

// import React from "react";
// import PropTypes from "prop-types";
// import If from "./If";
// import Post from "./Post";

// const PostList = (props) => {
//   const { posts, voteOnPost, deletePost, sortPost } = props;

//   return (
//     <div className="row">
//       <div className="col s12">
//         <button className="btn" onClick={() => sortPost("timestamp")}>
//           Order By Date
//         </button>{" "}
//         &nbsp;
//         <button className="btn" onClick={() => sortPost("voteScore")}>
//           Order By VoteScore
//         </button>{" "}
//         &nbsp;
//         <button className="btn" onClick={() => sortPost("commentCount")}>
//           Order By Comments
//         </button>{" "}
//         &nbsp;
//       </div>

//       {posts.map((post, index) => (
//         <Post
//           key={index}
//           post={post}
//           width="l6"
//           onClickVote={(vote) => voteOnPost({postId: post.id, vote: vote})}
//           onDeletePost={() => deletePost(post.id)} />
//       ))}

//       <If test={posts.length === 0}>
//         <p>No posts found.</p>
//       </If>
//     </div>
//   );
// };

// PostList.propTypes = {
//   posts: PropTypes.arrayOf(
//     PropTypes.shape({
//       author: PropTypes.string.isRequired,
//       body: PropTypes.string.isRequired,
//       category: PropTypes.string.isRequired,
//       commentCount: PropTypes.number.isRequired,
//       deleted: PropTypes.bool.isRequired,
//       id: PropTypes.string.isRequired,
//       title: PropTypes.string.isRequired,
//       voteScore: PropTypes.number.isRequired,
//     })
//   ).isRequired,
//   voteOnPost: PropTypes.func.isRequired,
//   deletePost: PropTypes.func.isRequired,
//   sortPost: PropTypes.func.isRequired,
// };

// export default PostList;
