import React from "react";
import { connect } from "react-redux";
import Headers from "../Header/header";
import { resetPassword } from "../../actions/index";
import Footer from "../Footer";

class ResetPassword extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      hero: {},
      email: "",
      disableBtn: false,
      btnText: "Reset Password",
      showSuccessMessage: false,
      showErrorMessage: false,
    };

    this.handleSubmit = this.handleSubmit.bind(this);
  }

  changeBtnText = (btnText) => {
    this.setState({ btnText });
  };

  handleFormValueChange(inputName, event) {
    let stateValue = {};
    stateValue[inputName] =
      event.target.type === "checkbox"
        ? event.target.checked
        : event.target.value;
    this.setState(stateValue);
  }

  handleSubmit(event) {
    event.preventDefault();

    if (!this.state.email) {
      return false;
    }

    // disable the button
    this.setState({
      disableBtn: true,
      showSuccessMessage: false,
      showErrorMessage: false,
    });
    this.changeBtnText("Sending...");

    fetch(
      `${process.env.REACT_APP_API_URL}/users/forgetpass/` + this.state.email
    )
      .then((res) => {
        res.json();
        if (res.status == 200) {
          this.setState({ showSuccessMessage: true });
        } else {
          this.setState({ showErrorMessage: true });
        }
        this.setState({ disableBtn: false }); // enable the button
        this.changeBtnText("Reset Password"); // change to button name
        const resetPasswordAction = resetPassword(this.state); // get action again to update state
        this.props.dispatch(resetPasswordAction); // Dispatch the contact from data
        this.setState({ email: "" }); // clear form data
      })
      .then((data) => this.setState({ data }));

    // // get action
    const resetPasswordAction = resetPassword(this.state);

    // // Dispatch the contact from data
    this.props.dispatch(resetPasswordAction);
  }

  componentDidMount() {
    /**
     * Your ajax will goes here to get data then call setState
     */
  }

  render() {
    return (
      <React.Fragment>
        <Headers />
        <div className="pt-265p pt-md-200p">
          <section className="page-forgot-password pt-30 pb-5">
            <div className="container">
              <div className="row align-items-center justify-content-between pt-sm-5 pt-md-5 pt-lg-0 login-container">
                <div className="col-md-8 col-xl-6 login-inner-container">
                  <div className="card login-signup-card shadow-lg mb-0">
                    <div className="card-body px-md-5 py-5">
                      <h1 className="mb-1 page-title">Password Reset</h1>
                      <p className="mb-5 text-muted">
                        Enter your email to get a password reset link.
                      </p>
                      {this.state.showErrorMessage && (
                        <p className="text-danger mb-2 font-weight-bold">
                          Something went wrong. Please try again.
                        </p>
                      )}
                      {this.state.showSuccessMessage && (
                        <p className="text-success mb-2 font-weight-bold">
                          A password reset link has been sent to your email.
                        </p>
                      )}
                      <form
                        className="login-signup-form"
                        method="post"
                        onSubmit={this.handleSubmit}
                      >
                        <div className="form-group">
                          <div className="input-group input-group-merge">
                            <input
                              value={this.state.email}
                              onChange={(e) =>
                                this.handleFormValueChange("email", e)
                              }
                              type="email"
                              name="email"
                              className="form-control"
                              placeholder="name@yourdomain.com"
                              pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$"
                              title="Please enter a valid email address"
                              required
                            />
                          </div>
                        </div>

                        <button
                          className={`btn-theme-primary btn-lg border-radius mt-4 mb-3 ${
                            this.state.disableBtn ? "disabled" : ""
                          }`}
                          id="signIn"
                          disabled={this.state.disableBtn}
                        >
                          {this.state.btnText}
                        </button>
                      </form>
                    </div>
                    <div className="card-footer bg-transparent border-top px-md-5 py-2">
                      <small className="text-muted text-center">
                        <span>Remember your password?&nbsp;</span>
                        <a className="text-carnation" href="/login">
                          Log in
                        </a>
                      </small>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/*<div className="bottom-img-absolute">
            <img
              src="img/hero-bg-shape-1.svg"
              alt="wave shape"
              className="img-fluid"
            />
          </div>
        */}
          </section>
        </div>
        <Footer />
      </React.Fragment>
    );
  }
}

export default connect((state) => ({
  state,
}))(ResetPassword);
