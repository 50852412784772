import React from "react";
import AIFC_Logo from "../../static/img/aifc-logo-white.svg";

class Footer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    /**
     * Your ajax will goes here to get data then call setState
     */
  }

  render() {
    return (
      <React.Fragment>
        <footer className="section footer ">
          <div className="content-container py-4">
            <div className="row">
              <div className="col-md-4 footer-side-links-container d-flex">
                <div className="text-md-left">
                  <img src={AIFC_Logo} alt="AIFC" className="footer-logo" />
                </div>
              </div>
              <div className="col-md-4  footer-links d-flex justify-content-center  align-items-center">
                <a href="/about" className="footer-link">
                  About Us
                </a>
                <a href="/community-guidelines" className="footer-link">
                  Community Guidelines
                </a>
                <a href="/faq" className="footer-link">
                  FAQ
                </a>
                <a href="/disclaimer" className="footer-link">
                  Disclaimer
                </a>
              </div>
              <div className="col-md-4 d-flex align-items-center justify-content-center mt-4 m-md-0">
                <div className="reach-us">
                  <p className="reach-us-at text-md-right">Reach Us At</p>
                  <a
                    href="mailto:aifacilitatorcommunity.cbse@gmail.com"
                    className="contact-email text-right"
                  >
                    aifacilitatorcommunity.cbse@gmail.com
                  </a>
                  <p className="reach-us-at text-center text-md-right mb-3">
                    Follow us to get exciting updates!
                  </p>
                  <div className="social-media-links text-md-right">
                    <a
                      href="https://www.facebook.com/AI4Youth/"
                      target="_blank"
                      rel="noopener noreferrer"
                      className="social-media-link"
                    >
                      <img src="/icons/facebook.svg" alt="Facebook" />
                    </a>
                    <a
                      href="https://twitter.com/Ai4Youth?s=20"
                      target="_blank"
                      rel="noopener noreferrer"
                      className="social-media-link"
                    >
                      <img src="/icons/twitter.svg" alt="Twitter" />
                    </a>
                    <a
                      href="https://www.secure.instagram.com/ai4.youth/"
                      target="_blank"
                      rel="noopener noreferrer"
                      className="social-media-link"
                    >
                      <img src="/icons/instagram.svg" alt="Instagram" />
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="footer-bottom">
            <p className="text-center copyright-text text-white pt-1 pb-1">
              © All Rights Reserved.
            </p>
          </div>
        </footer>
      </React.Fragment>
    );
  }
}

export default Footer;
