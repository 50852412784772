import React from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";

const PDFIconWrapper = styled.div`
  width: 70px;
`;

const IndiaAIFestivalProjectShowcase = ({ year }) => {
  const { t } = useTranslation();
  return (
    <div className="project-showcase mb-3 mb-md-5">
      <h3> {t(`project_showcase_title.${year}`)}</h3>
      <div className="row">
        <div className="offset-md-2 col-md-4 align-items-center justify-content-center flex-column">
          <div className="embed-responsive embed-responsive-16by9">
            <video
              className="embed-responsive-item"
              controls
              src={`/assets/video/project-showcase-video-${year}.mp4`}
            />
          </div>
        </div>
        <div className="col-md-4 mt-3 d-flex mt-md-0">
          <div className="border w-100 d-flex align-items-center justify-content-center flex-column">
            <h4 className="text-center">
              {t(`project_showcase_pdf_text.${year}`)}
            </h4>
            <a
              href={`/assets/pdf/project-showcase-pdf-${year}.pdf`}
              target="_blank"
              className="text-primary"
            >
              <PDFIconWrapper>
                <img src="/images/pdf-icon.jpeg" className="w-100" />
              </PDFIconWrapper>
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default IndiaAIFestivalProjectShowcase;
