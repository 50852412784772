import React, { Component } from "react";
import slugify from "slugify";
import Header from "../components/Header/header";
import SecondHeader from "../components/Header/secondheader";
import Footer from "../components/Footer/index";
import { FiExternalLink } from "react-icons/fi";
import { AiOutlineDown, AiOutlineUp } from "react-icons/ai";

class LessonPlans extends Component {
  constructor(props) {
    super(props);
    this.state = {
      lessonPlans: [],
      collapsed: [],
    };
  }

  getLessonPlans() {
    fetch(`${process.env.REACT_APP_API_URL}/lesson-plans`)
      .then((response) => response.json())
      .then((result) => {
        this.setState({
          lessonPlans: result.lessonPlans,
        });
      });
  }

  componentDidMount() {
    this.getLessonPlans();
  }

  render() {
    const listItem = (title, description, link, linkLabel) => (
      <div className="col-md-6 mt-3">
        <div className="mtbl-3 h-100">
          <div class="list-group-item list-group-item-action flex-column align-items-start h-100">
            <div class="d-flex w-100 justify-content-between">
              <h4 class="mb-1">{title}</h4>
              <a
                href={link}
                class="btn btn-primary h-100 d-flex align-items-center"
                target="_blank"
                rel="noopener noreferrer"
              >
                {linkLabel ? linkLabel : "View"}{" "}
                <FiExternalLink className="view-icon ml-1" />
              </a>
            </div>
            <p class="mb-1 text-justify">{description}</p>
          </div>
        </div>
      </div>
    );

    // Get all unique grades for the lessonPlans like 6, 9 or 10
    const getGrades = this.state.lessonPlans.map(
      (lessonPlan) => lessonPlan.class
    );
    const uniqueGrades = [...new Set(getGrades)];

    // Get All subjects of lessonPlans like Deep dive and academic
    const getSubjects = this.state.lessonPlans.map(
      (lessonPlan) => lessonPlan.subject
    );
    const uniqueSubjects = [...new Set(getSubjects)];
    uniqueSubjects.sort();

    // Create an array of all the lessonPlans of a grade
    const gradeList = uniqueGrades.map((grade) => {
      let lessonPlansForGrade = this.state.lessonPlans.filter(
        (lessonPlan) => lessonPlan.class === grade
      );

      // Separate the data into its subjects
      const subject = uniqueSubjects.map((lessonPlanSubject) => {
        let uniqueSubjectData = lessonPlansForGrade.filter(
          (lessonPlan) => lessonPlan.subject === lessonPlanSubject
        );

        // sort according to order
        uniqueSubjectData.sort((a, b) => a.order - b.order);

        uniqueSubjectData = uniqueSubjectData.map((lessonPlan) => ({
          title: lessonPlan.title,
          description: lessonPlan.description,
          link: lessonPlan.link,
          linkLabel: lessonPlan.linkLabel,
        }));

        return {
          title: lessonPlanSubject,
          data: uniqueSubjectData,
        };
      });

      return {
        title: grade,
        subject,
      };
    });

    const list = (
      <div class="accordion w-100">
        {gradeList.map((grade, index) => {
          return (
            <div
              class="blog-container container mx-auto shadow rounded"
              id={slugify(grade.title, { lower: true })}
              style={{ scrollMarginTop: "160px" }}
            >
              <div>
                <div class="mb-0">
                  <a
                    onClick={() =>
                      this.setState({
                        collapsed: this.state.collapsed.includes(index)
                          ? this.state.collapsed.filter(
                              (item) => item !== index
                            )
                          : [...this.state.collapsed, index],
                      })
                    }
                    data-toggle="collapse"
                    href={`#grade${index}`}
                    role="button"
                    aria-expanded="false"
                    aria-controls={`grade${index}`}
                  >
                    <div
                      className={`${
                        this.state.collapsed.includes(index)
                          ? ""
                          : "border-bottom-3 border-oslo-gray"
                      } d-flex mx-4`}
                    >
                      <h2 className="px-0 text-left pt-3">{grade.title}</h2>
                      <div className="ml-auto p-3">
                        {this.state.collapsed.includes(index) ? (
                          <AiOutlineDown className="icon w-40 h-40" />
                        ) : (
                          <AiOutlineUp className="icon w-40 h-40" />
                        )}
                      </div>
                    </div>
                  </a>
                </div>
              </div>

              <div id={`grade${index}`} class="collapse show">
                <div class="card-body pt-0">
                  {grade.subject.map((data) => {
                    return (
                      <section
                        id={slugify(`${grade.title} ${data.title}`, {
                          lower: true,
                        })}
                      >
                        <h3 className="mt-4 mb-0 pl-3 text-left">
                          {data.title}
                        </h3>
                        <div className="row">
                          {data.data.map((item) =>
                            listItem(
                              item.title,
                              item.description,
                              item.link,
                              item.linkLabel
                            )
                          )}
                        </div>
                      </section>
                    );
                  })}
                </div>
              </div>
            </div>
          );
        })}
      </div>
    );

    return (
      <React.Fragment>
        {localStorage.getItem("jwtToken") == null ? (
          <Header />
        ) : (
          <SecondHeader />
        )}
        <div className="pt-265p pt-md-200p">
          <div className="mx-5 mb-5">
            <div class="container">
              <div class="row">
                <div class="col">
                  <h2 class="pb-3 border-bottom mb-4">Lesson Plans</h2>
                  <div class="row">{list}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </React.Fragment>
    );
  }
}

export default LessonPlans;
