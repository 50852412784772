import React, { Component } from "react";
import ChangingFields from "./ChangingFields/ChangingFields";

class CommunitySection extends Component {
  render() {
    const initialState = {
      title: "Connect and Collaborate",
      description: (
        <ul className="list-group join-list text-white">
          <li className="mb-md-3">
            Be a part of a vibrant community of facilitators.
          </li>
          <li className="mb-md-3">
            Build network with enthusiastic facilitators engaged in practicing
            AI in Education.
          </li>
          <li className="mb-md-3">
            Share knowledge and insights; your best practices and inspire
            others.
          </li>
        </ul>
      ),
      icon: "fa-connectdevelop",
      imageUrl: "images/connect.jpeg",
    };
    const joiningReasons = [
      initialState,

      {
        title: "Get Access to the right toolset",
        description: (
          <ul className="list-group join-list text-white">
            <li className="mb-md-3">
              Attend live online training sessions with Intel certified AI
              coaches and other Industry experts.
            </li>
            <li className="mb-md-3">
              Explore the AI World on your own through the self-learning
              environment.
            </li>
            <li className="mb-md-0">
              Participate in Challenges, Competitions and win digital badges and
              certificates.
            </li>
          </ul>
        ),
        icon: "fa-server",
        imageUrl: "images/toolset.jpg",
      },
      {
        title: "Build AI enabled solutions",
        description: (
          <ul className="list-group join-list text-white">
            <li className="mb-md-3">
              Give wings to your ideas and create AI-led lesson plans.
            </li>
            <li className="mb-md-3">
              Support students to develop social impact solutions with support
              from AI for Youth coaches and mentors.
            </li>
            <li className="mb-md-0">
              Contribute towards Sustainable Development Goals (SDG’s) in your
              own way!
            </li>
          </ul>
        ),

        icon: "fa-database",
        imageUrl: "images/build.jpg",
      },
    ];
    return (
      <section className="vertical-scrolling animated-row ">
        <div className="section-inner">
          <ChangingFields
            title="WHY JOIN AI FACILITATOR COMMUNITY?"
            reasons={joiningReasons}
            setDetails={this.setDetails}
            initialState={initialState}
          />
        </div>
      </section>
    );
  }
}

export default CommunitySection;
