import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { logoutUser } from "../../actions/authAction";
import Footer from "../Footer/index";
import SecondHeader from "../Header/secondheader";
import { AiOutlineCheckCircle } from "react-icons/ai";
import { MdCheckCircle } from "react-icons/md";
class Letslearn extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: {
        studentData: [],
        courseData: [],
      },
    };
  }

  componentDidMount() {
    const id = localStorage.getItem("studentId");
    fetch(`${process.env.REACT_APP_API_URL}/letslearn/` + id, {
      headers: {
        "x-access-token": localStorage.getItem("jwtToken"),
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    })
      .then((response) => response.json())
      .then(
        (result) => {
          this.setState({
            isLoaded: true,
            data: result.data,
          });
        },
        (error) => {
          this.setState({
            isLoaded: true,
            error,
          });
        }
      );
  }

  render() {
    const { data } = this.state;
    return (
      <React.Fragment>
        <SecondHeader />
        <section className="our-blog-section gray-light-bg single-blog-container">
          <div className="blog-container container mx-auto shadow rounded">
            {data.first_time_letslearn && (
              <div className="row mx-0">
                <div className="col-12 px-0">
                  <div
                    className="alert alert-success alert-dismissible fade show"
                    role="alert"
                  >
                    <h4 className="alert-heading">You're curious!</h4>
                    <p className="mb-0">This is an amazing place for you.</p>
                    <button
                      type="button"
                      className="close"
                      data-dismiss="alert"
                      aria-label="Close"
                    >
                      <span aria-hidden="true">&times;</span>
                    </button>
                  </div>
                </div>
              </div>
            )}
            <div className="row">
              {data.studentData.map((studentData, j) => (
                <div key={j}>
                  <div className="row col-sm-12 mx-auto">
                    <h1 className="text-left">Let's Learn</h1>
                    <p className="sub-heading text-justify">
                      Learn the essentials of Artificial Intelligence and
                      understand the fastest-growing and most transformational
                      technologies of our time through video resources. Develop
                      your skill-set and learn the ins-and-outs of Python to get
                      the necessary skills for building AI. Start your journey
                      today!
                    </p>
                  </div>
                </div>
              ))}
            </div>
            <div>
              <h2 class="course-card-lets-learn">Basic Courses</h2>
              {data.courseData.map(
                (courseData, j) =>
                  courseData.courseType === "basic" && (
                    <div className="pb-50" key={j}>
                      <CourseCard data={courseData} />
                    </div>
                  )
              )}

              <h2 class="course-card-lets-learn">Intermediate Courses</h2>
              {data.courseData.map(
                (courseData, j) =>
                  courseData.courseType === "intermediate" && (
                    <div className="pb-50" key={j}>
                      <CourseCard data={courseData} />
                    </div>
                  )
              )}

              <h2 class="course-card-lets-learn">Expert Courses</h2>
              {data.courseData.map(
                (courseData, j) =>
                  courseData.courseType === "expert" && (
                    <div className="pb-50" key={j}>
                      <CourseCard data={courseData} />
                    </div>
                  )
              )}
            </div>
          </div>
        </section>
        <Footer />
      </React.Fragment>
    );
  }
}

Letslearn.propTypes = {
  logoutUser: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

const CourseCard = ({ data }) => {
  return (
    <div className="row mar-t-15">
      <div className="col-sm-12">
        <div className="d-flex justify-content-between">
          <h3 class="border-bottom-light">{data.title} </h3>
          <div className="no-r-padding">
            <a className="blue-btn" href={"/single_course/" + data.startsWith}>
              Start Learning
            </a>
          </div>
        </div>
        <div>
          <p className="lesson-desc without-limit text-left mar-t-15 mb-n2 ">
            {data.desc}
          </p>
        </div>
      </div>
      <div className="col-md-12 mt-n2 ">
        <h4 className="text-left">Course Module</h4>
      </div>
      <div className="col-sm-7">
        {data.modules.map((modules, k) => (
          <ul key={k}>
            {modules.topics.map((topic, index) => (
              <ul key={index}>
                <li
                  className="topic-title"
                  onClick={() =>
                    this.props.history.push(`/single_course/${topic.order}`)
                  }
                >
                  {topic.watched === true ? (
                    <MdCheckCircle
                      className="topic-icon"
                      size={30}
                      color="green"
                    />
                  ) : (
                    <AiOutlineCheckCircle
                      size={30}
                      color="green"
                      className="topic-icon"
                    />
                  )}

                  {topic.title}
                </li>
              </ul>
            ))}
          </ul>
        ))}
      </div>
      <div className="col-sm-5 text-center">
        <img className="course-lets-img" src={data.cover} alt="letslearnimg" />
      </div>
    </div>
  );
};

export default connect(mapStateToProps, { logoutUser })(Letslearn);
