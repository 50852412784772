import React, { Component } from "react";
import Header from "../Header/secondheader";
import SecondHeader from "../Header/secondheader";
import { withRouter, Link, Redirect } from "react-router-dom";
import Footer from "../Footer/index";
import { FaFacebook, FaWhatsapp, FaInstagram } from "react-icons/fa";
import moment from "moment";
import "react-notifications/lib/notifications.css";
import {
  NotificationContainer,
  NotificationManager,
} from "react-notifications";
import returnIcon from "../../static/img/return.png";

class singleblog extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: null,
      otherBlogs: [],
      id: props.data,
      likesCount: 0,
      liked: false,
    };
  }

  componentDidMount() {
    const { location } = this.props;
    const sid = localStorage.getItem("studentId");

    fetch(
      `${process.env.REACT_APP_API_URL}/blogs/${sid}${location.pathname.replace(
        /\/singleblog/,
        ""
      )}`,
      {
        headers: {
          "x-access-token": localStorage.getItem("jwtToken"),
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      }
    )
      .then((response) => response.json())
      .then(
        (result) => {
          this.setState({
            isLoaded: true,
            data: result.blogs,
            otherBlogs: result.otherBlogs,
          });
        },
        (error) => {
          this.setState({
            isLoaded: true,
            error,
          });
        }
      );

    fetch(
      `${process.env.REACT_APP_API_URL}/blogs/likes${location.pathname.replace(
        /\/singleblog/,
        ""
      )}`,
      {
        headers: {
          "x-access-token": localStorage.getItem("jwtToken"),
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      }
    )
      .then((response) => response.json())
      .then(
        (result) => {
          this.setState({
            likesCount: result.count,
          });
        },
        (error) => {
          console.log(error);
        }
      );

    fetch(
      `${process.env.REACT_APP_API_URL}/blogs${location.pathname.replace(
        /\/singleblog/,
        ""
      )}/like/${sid}`,
      {
        headers: {
          "x-access-token": localStorage.getItem("jwtToken"),
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      }
    )
      .then((response) => response.json())
      .then(
        (result) => {
          this.setState({
            liked: result.liked,
          });
        },
        (error) => {
          console.log(error);
        }
      );
  }

  getArrayDoc(doc) {
    var arr = [];
    var finalArr = [];
    if (doc.Description !== undefined) {
      var dataArr = doc.Description.split("\n");
      for (var i = 0; i < dataArr.length; i++) {
        if (dataArr[i].trim() !== "") {
          if (dataArr[i].startsWith("![text](/uploads/")) {
            finalArr.push({
              type: "img",
              value:
                "http://139.59.25.249/" +
                dataArr[i].slice(8, dataArr[i].length - 1),
            });
          } else if (dataArr[i].startsWith("![text](http")) {
            finalArr.push({
              type: "img",
              value: dataArr[i].slice(8, dataArr[i].length - 1),
            });
          } else {
            finalArr.push({
              type: "text",
              value: dataArr[i].replace("# ", ""),
            });
          }
        }
      }
    }
    doc.content = finalArr;
    arr.push(doc);
    return arr;
  }

  copyLink() {
    navigator.clipboard.writeText("/singleblog");
    NotificationManager.success("Link successfully copied");
  }

  likeBlog() {
    const userId = localStorage.getItem("studentId");
    const blogId = this.state.data[0].id;

    fetch(`${process.env.REACT_APP_API_URL}/blogs/${blogId}/like`, {
      method: "PUT",
      body: JSON.stringify({
        userId: userId,
      }),
      headers: {
        "x-access-token": localStorage.getItem("jwtToken"),
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    })
      .then((response) => response.json())
      .then(
        (result) => {
          const likesCount = this.state.likesCount;
          const newCount = result.liked ? likesCount + 1 : likesCount - 1;
          this.setState({
            liked: result.liked,
            likesCount: newCount,
          });
        },
        (error) => {
          console.log(error);
        }
      );
  }

  render() {
    const { data } = this.state;
    if (data && data.length === 0) {
      return <Redirect to="/404page" />;
    }

    const likeBtnClass = this.state.liked
      ? "btn text-white"
      : "btn text-dark btn-outline-secondary";
    const likeColor = this.state.liked ? "#b73971" : "#ffffff";
    const likeText = this.state.liked ? "Liked" : "Like";

    return (
      <div>
        {localStorage.getItem("jwtToken") == null ? (
          <Header />
        ) : (
          <SecondHeader />
        )}
        <NotificationContainer />
        {data &&
          data.map((data, index) => (
            <section
              className="our-blog-section gray-light-bg single-blog-container"
              key={index}
            >
              <div className="blog-container container mx-auto shadow">
                <div className="col-md-2 text-left">
                  <Link to="/blog">
                    <div
                      className="text-carnation go-back-mar"
                      style={{ fontSize: "18px", fontWeight: "bold" }}
                    >
                      <img
                        className="return-icon"
                        src={returnIcon}
                        alt="icon"
                      />
                      <p className="return-label">Go Back</p>
                    </div>
                  </Link>
                </div>
                <div
                  className="row pb-15 pt-15 text-left"
                  style={{ justifyContent: "space-evenly" }}
                >
                  <div className="col-md-8 text-left">
                    <h3 className="text-carnation text-left">{data.title}</h3>
                  </div>
                  <div className="col-md-3 no-padding text-right">
                    <button
                      onClick={this.likeBlog.bind(this)}
                      className={`btn ${likeBtnClass}`}
                      style={{ background: likeColor }}
                      title={likeText}
                    >
                      <span className="ti-heart mr-2"></span>
                      {this.state.likesCount}
                    </button>
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-12">
                    <div className="">
                      <div className="col-md-12 mb-4">
                        <div className="single-blog-card card border-0 shadow-sm m-auto">
                          <div className="blogs-tag">
                            {data.tags &&
                              data.tags.slice(0, 3).map((tags, key) => (
                                <span
                                  key={key}
                                  className="mar-r-8 badge badge-pill badge-primary"
                                >
                                  {tags.value}
                                </span>
                              ))}
                          </div>

                          <img
                            src={data.cover}
                            className="card-img-top hero-img-blog position-relative"
                            alt="blog"
                          />

                          <div className="card-footer p-2">
                            <div className="single-blog-content1 mt-2">
                              <div>
                                <h5 className="h5-author">Author</h5>
                                <span className="blogs-footer">
                                  <img
                                    src={data.student_data[0].profile_pic}
                                    alt="author"
                                  />
                                </span>
                                <span className="blogs-footer-text ">
                                  {`${data.student_data[0].first_name}  ${data.student_data[0].last_name}`}
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="mb-50">
                  <div className="col-md-12 single-blog-content2">
                    <p
                      className="data-block-caption"
                      dangerouslySetInnerHTML={{
                        __html: data.content,
                      }}
                    />
                  </div>
                </div>
                <div className="col-md-12">
                  <hr className="gray-separator"></hr>
                </div>
                <div className="mb-50">
                  <h4 className="h4-margin">Related Blogs</h4>
                  <div className="container">
                    <div className="d-flex flex-wrap justify-content-between">
                      {this.state.otherBlogs.map((data, index) => (
                        <div
                          className="single-blog-card card border-0 shadow-sm"
                          key={index}
                          style={{ width: "300px" }}
                        >
                          {data.tags === null
                            ? null
                            : data.tags.map((tags, key) => (
                                <span
                                  key={key}
                                  className="category position-absolute badge badge-pill badge-primary"
                                >
                                  {tags.value}
                                </span>
                              ))}

                          <img
                            src={data.cover}
                            className="card-img-top position-relative"
                            alt="blog"
                            style={{ height: "160px" }}
                            onClick={() =>
                              (window.location.href = `/singleblog/${data.id}`)
                            }
                          />

                          <div
                            className="card-body"
                            style={{
                              maxHeight: "150px",
                              color: "#435370",
                              padding: "14px",
                            }}
                          >
                            <h3
                              style={{
                                cursor: "pointer",
                                minHeight: "unset",
                                fontSize: "18px",
                                fontWeight: "800",
                              }}
                              className="h5 card-title"
                              onClick={() =>
                                this.props.history.push(
                                  `/singleblog/${data.id}`
                                )
                              }
                            >
                              {data.title.substring(0, 45) + "..."}
                            </h3>
                            <p
                              className="card-text"
                              style={{
                                lineHeight: "1.35",
                                color: "rgb(60, 76, 107)",
                              }}
                            >
                              {data.excerpt.substring(0, 105) + "..."}
                            </p>
                          </div>

                          {data.student_data.map((student_data, index) => (
                            <div className="d-flex" style={{ width: "100%" }}>
                              <div
                                style={{ fontSize: "12px", width: "100%" }}
                                className="d-flex p-2"
                              >
                                <img
                                  className="img blog-profile-pic mx-2"
                                  src={student_data.profile_pic}
                                  alt="User-Icon"
                                />

                                <span>{student_data.first_name}</span>
                              </div>
                              <div
                                className="d-flex justify-content-end"
                                style={{ width: "100%" }}
                              >
                                <a
                                  href={`/singleblog/${data.id}`}
                                  className="btn btn-primary enroll-button py-2 text-white"
                                >
                                  Read full blog
                                </a>
                              </div>
                            </div>
                          ))}
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            </section>
          ))}

        <Footer />
      </div>
    );
  }
}

export default withRouter(singleblog);
