import React, { Component } from "react";
import Select from "react-select";
import CKEditor from "react-ckeditor-component";
import axios from "axios";
import SecondHeader from "../Header/secondheader";
import "react-notifications/lib/notifications.css";
import {
  NotificationContainer,
  NotificationManager,
} from "react-notifications";

class Editblog extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      myBlogs: [],
      selectedOption: null,
      content: "",
      image: "",
      id: "",
      imageUploading: false,
    };
  }

  onIChange = (evt) => {
    this.setState({
      content: evt.editor.getData(),
    });
  };

  fileSelectedHandler = (event) => {
    this.setState({
      selectedFile: event.target.files[0],
    });
  };

  photoUploadHandler = (e) => {
    const bcs = e.target.files[0];
    var reader = new FileReader();
    reader.onload = function (e) {
      document.getElementById("coverThumbnail").src = e.target.result; // get loaded data and render thumbnail.
    };
    reader.readAsDataURL(bcs);
    const fd = new FormData();
    fd.append("file", bcs);
    this.setState({ imageUploading: true, image: bcs.name });
    axios
      .post(
        `${process.env.REACT_APP_API_URL}/admin/upload`,
        fd,
        {
          headers: {
            "x-access-token": localStorage.getItem("jwtToken"),
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        },
        {
          onUploadProgress: (ProgressEvent) => {
            this.setState({
              loaded: (ProgressEvent.loaded / ProgressEvent.total) * 100,
            });
          },
        }
      )
      .then((res) => {
        this.setState({ image: res.data.path, imageUploading: false });
      });
  };

  createNotification = (type) => {
    // return () => {
    // eslint-disable-next-line default-case
    switch (type) {
      case "info":
        NotificationManager.info("Info message");
        break;
      case "success":
        NotificationManager.success("Successfully Updated!", "Update", 7000);

        break;
      case "warning":
        NotificationManager.warning(
          "Warning message",
          "Close after 3000ms",
          3000
        );
        break;
      case "error":
        NotificationManager.error(
          "Error message",
          "Something is Wrong",
          5000,
          () => {
            alert("callback");
          }
        );
        break;
    }
  };

  onChange = (e) => {
    this.setState({ [e.target.id]: e.target.value });
  };

  componentDidMount() {
    this.myBlogs();
    fetch(`${process.env.REACT_APP_API_URL}/forums/tags`, {
      headers: {
        "x-access-token": localStorage.getItem("jwtToken"),
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    })
      .then((res) => res.json())
      .then((data) => {
        this.setState({
          data: data.tags,
        });
      });
  }

  myBlogs = () => {
    const { location } = this.props;
    fetch(
      `${process.env.REACT_APP_API_URL}/blogs${location.pathname.replace(
        /\/editblog/,
        ""
      )}`,
      {
        headers: {
          "x-access-token": localStorage.getItem("jwtToken"),
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      }
    )
      .then((response) => response.json())
      .then(
        (result) => {
          this.setState({
            isLoaded: true,
            myBlogs: result.blogs,
            content: result.blogs[0].content,
            id: result.blogs[0].id,
            selectedOption: result.blogs[0].tags,
            image: result.blogs[0].cover,
          });
        },
        (error) => {
          this.setState({
            isLoaded: true,
            error,
          });
        }
      );
  };

  handleChange = (selectedOption) => {
    this.setState({ selectedOption });
  };

  onSubmit = (e) => {
    const id = localStorage.getItem("studentId");
    e.preventDefault();
    fetch(`${process.env.REACT_APP_API_URL}/blogs/` + this.state.id, {
      method: "PUT",

      headers: {
        "x-access-token": localStorage.getItem("jwtToken"),
        Accept: "application/json",
        "Content-Type": "application/json",
      },

      body: JSON.stringify({
        cover: this.state.image,
        title: e.target.title.value,
        content: this.state.content,
        tags: this.state.selectedOption,
        author: id,
        excerpt: e.target.excerpt.value,
      }),
    })
      .then((res) => res.json())
      .then((res) => {
        this.createNotification("success");
        this.props.history.push("/blog");
        if (res.status === 400) {
          this.createNotification("error");
        }
      });
  };

  render() {
    const { selectedOption } = this.state;
    return (
      <React.Fragment>
        <SecondHeader />
        <NotificationContainer />
        {this.state.myBlogs.map((data, index) => (
          <div className="container pt-265p pt-md-200p" key={index}>
            <div className="col-md-12 col-lg-12  pb-50">
              <div className="card login-signup-card shadow-lg mb-0">
                <div className="card-body px-md-5 py-5">
                  <form
                    className="login-signup-form"
                    method="post"
                    onSubmit={this.onSubmit}
                  >
                    <div
                      className="row"
                      style={{
                        display: "flex",
                        justifyContent: "space-evenly",
                      }}
                    >
                      <h3 className="text-carnation">Edit Blog</h3>
                    </div>
                    <div
                      className="row"
                      style={{
                        display: "flex",
                        justifyContent: "space-evenly",
                      }}
                    >
                      <div className="col-md-8 mar-b-30">
                        <label>Cover Image</label>
                        <br />
                        <div className="row blogs-cover">
                          <input
                            onChange={this.photoUploadHandler}
                            type="file"
                            accept="image/*"
                            className="form-control"
                            required={this.state.image === ""}
                          />
                          <small>
                            {this.state.image === "" ? (
                              "Select Cover Image*"
                            ) : (
                              <img
                                id="coverThumbnail"
                                src={this.state.image}
                                height="75"
                                className="mt-1"
                                alt="cover thumbnail"
                              />
                            )}
                          </small>
                        </div>
                      </div>
                    </div>
                    <div
                      className="row"
                      style={{
                        display: "flex",
                        justifyContent: "space-evenly",
                      }}
                    >
                      <div className="col-sm-8 mar-b-30">
                        <div className="form-group">
                          <label className="pb-1">Title*</label>
                          <div className="input-group input-group-merge">
                            <input
                              defaultValue={data.title}
                              onChange={this.onChange}
                              type="text"
                              name="title"
                              className="form-control"
                              placeholder="Title"
                              required
                            />
                          </div>
                        </div>
                      </div>
                      <div className="col-sm-8 mar-b-30">
                        <div className="form-group">
                          <label className="pb-1">Excerpt</label>
                          <div className="input-group input-group-merge">
                            <textarea
                              defaultValue={data.excerpt}
                              className="form-control"
                              name="excerpt"
                              placeholder="Text here"
                            ></textarea>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      className="row"
                      style={{
                        display: "flex",
                        justifyContent: "space-evenly",
                      }}
                    >
                      <div className="form-group col-sm-8 mar-b-30">
                        <label className="pb-1">Description</label>
                        <div className="input-group-signup input-group-merge">
                          <CKEditor
                            defaultValue={data.content}
                            activeClass="p10"
                            name="content"
                            content={this.state.content}
                            events={{
                              change: this.onIChange,
                            }}
                          />
                        </div>
                      </div>
                    </div>
                    <div
                      className="row"
                      style={{
                        display: "flex",
                        justifyContent: "space-evenly",
                      }}
                    >
                      <div className="form-group col-sm-8 mar-b-30">
                        <label className="pb-1">Tags</label>
                        <div className="input-group-signup input-group-merge">
                          <Select
                            className="form-select"
                            // eslint-disable-next-line no-undef
                            value={selectedOption}
                            onChange={this.handleChange}
                            isMulti
                            options={this.state.data}
                          />
                        </div>
                      </div>
                    </div>

                    <button
                      className={`btn-blue btn-lg btn-block solid-btn border-radius mt-4 mb-3 signup-btn ${
                        this.state.disableBtn || this.state.imageUploading
                          ? "c-not-allowed"
                          : ""
                      }`}
                      id="signIn"
                      disabled={
                        this.state.disableBtn || this.state.imageUploading
                      }
                    >
                      {this.state.imageUploading ? "Uploading..." : "Submit"}
                    </button>
                  </form>
                </div>
              </div>
            </div>
          </div>
        ))}
      </React.Fragment>
    );
  }
}

export default Editblog;
