import React from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import IndiaAIFestivalCTA from "./IndiaAIFestivalCTA";
import { isSubmissionOpen } from "../../utils/impactFestival";

const CatchyHeading = styled.div`
  font-size: 18px;
  line-height: 1.25;
  color: #ff8f4e;
  text-align: center;
  font-weight: 800;

  animation: pulse 2s infinite alternate ease-in-out;

  @keyframes pulse {
    0% {
      transform: scale(1);
    }
    50% {
      transform: scale(1.1);
    } /* Expand to 110% */
    100% {
      transform: scale(1);
    } /* Return to original size */
  }

  @media (min-width: 992px) {
    font-size: 30px;
  }
`;

const IndiaAIFestivalContact = ({ year }) => {
  const { t } = useTranslation();

  return isSubmissionOpen(year) ? (
    <div className="d-flex align-items-center flex-column">
      {/* <CatchyHeading>
        {t("calling_all_innovators_line1")}
        <br />
        {t("calling_all_innovators_line2")}
      </CatchyHeading> */}
      <IndiaAIFestivalCTA year={year} />
    </div>
  ) : null;
};

export default IndiaAIFestivalContact;
