import React from "react";
import { connect } from "react-redux";
import serializeForm from "form-serialize";
import PropTypes from "prop-types";
import { getRandomId } from "../../utils";
import { actionAddPost } from "../../actions/forumindex";
import CategoriesDropdown from "./CategoriesDropdown";
import TagsDropdown from "./TagsDropdown";

export class PostNew extends React.Component {
  constructor() {
    super();
    this.state = {
      showLoader: false,
    };

    this.handleSubmit = this.handleSubmit.bind(this);
    this.goBack = this.goBack.bind(this);
  }

  state = {
    showLoader: false,
  };
  onChange = (e) => {
    this.setState({ [e.target.id]: e.target.value });
  };

  handleSubmit = (e) => {
    e.preventDefault();
    const values = serializeForm(e.target, { hash: true });
    this.props.createPost(values);
    if (this.props.onCreatePost) this.props.onCreatePost();
  };

  goBack = () => {
    if (this.props.onCreatePost) this.props.onCreatePost();
  };

  render() {
    const timestamp = Date.now();
    const id = localStorage.getItem("studentId");
    return (
      <section>
        {/* <h4 className="">Create a Post</h4> */}
        <div>
          <div className="col-md-12 col-lg-12 ">
            <div className=" mb-0">
              <div className="">
                <form
                  className="login-signup-form"
                  method="post"
                  onSubmit={this.handleSubmit}
                >
                  <input type="hidden" name="id" defaultValue={getRandomId()} />
                  <input
                    type="hidden"
                    name="timestamp"
                    defaultValue={timestamp}
                  />
                  <input type="hidden" name="student_id" defaultValue={id} />
                  <div className="row create-new-post">
                    <h3 className="text-carnation mar-b-20">Create New Post</h3>
                  </div>
                  <div className="row create-new-post width">
                    <div className="form-group col-md-8 mar-b-15 ">
                      <label>Title</label>
                      <div className=" form-group">
                        <input
                          id="title"
                          type="text"
                          className="form-control "
                          name="title"
                          required
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row create-new-post width">
                    <div className="form-group col-md-8 mar-b-15">
                      {/* <div className="form-group"> */}
                      <label className="active">Categories</label>
                      <CategoriesDropdown
                        isLink={false}
                        className="no-l-mar"
                        firstValue="Categories"
                      />

                      {/* </div> */}
                    </div>
                    <div className="form-group col-sm-8 mar-b-15">
                      <div className="form-group">
                        <label>Tags</label>
                        <TagsDropdown />
                      </div>
                    </div>
                  </div>

                  <div className="row create-new-post width">
                    <div className="form-group col-sm-8 mar-b-15">
                      <label className="active">Enter your detailed post</label>
                      <textarea
                        id="description"
                        className="form-control"
                        name="body"
                        required
                        rows="5"
                      />
                    </div>
                  </div>
                  <div className="row create-new-post">
                    <div className="form-group col-sm-8 mar-b-15">
                      <button
                        type="button"
                        className="btn-blue"
                        onClick={this.goBack}
                      >
                        Cancel
                      </button>{" "}
                      &nbsp;
                      <button type="submit" className="btn-blue">
                        Publish
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

PostNew.propTypes = {
  onCreatePost: PropTypes.func.isRequired,
  createPost: PropTypes.func.isRequired,
};
const mapStateToProps = (state) => {
  return {
    // auth: state.auth,
    // error: state.authFail,
  };
};

const mapDispatchToProps = (dispatch) => ({
  createPost: (post) => dispatch(actionAddPost(post)),
});

export default connect(mapStateToProps, mapDispatchToProps)(PostNew);
