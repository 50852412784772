import React, { Component } from "react";
import moment from "moment";
import Footer from "../../components/Footer/index";
import SecondHeader from "../../components/Header/secondheader";
import CommentCard from "./CommentCard";
import returnIcon from "../../static/img/return.png";

class PostDetail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      posts: [],
    };
  }

  componentDidMount() {
    const { location } = this.props;
    fetch(
      `${process.env.REACT_APP_API_URL}/forums/posts${location.pathname.replace(
        /\/postdetails/,
        ""
      )}`,
      {
        headers: {
          "x-access-token": localStorage.getItem("jwtToken"),
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      }
    )
      .then((response) => response.json())
      .then(
        (result) => {
          this.setState({
            isLoaded: true,
            posts: result.posts,
          });
        },
        (error) => {
          this.setState({
            isLoaded: true,
            error,
          });
        }
      );
  }

  render() {
    const { posts } = this.state;

    return (
      <div>
        <SecondHeader />

        <div className="limiter shadow mt-30 blog-container">
          <div className="row">
            <div
              className="text-carnation"
              style={{
                fontSize: "18px",
                fontWeight: "bold",
                paddingLeft: "30px",
              }}
              onClick={() => this.props.history.goBack()}
            >
              <img className="return-icon" src={returnIcon} alt="icon" />{" "}
              <p className="return-label"> Go Back</p>
            </div>

            {/* <div className="col-md-12">
              <div className="section-heading">
                <h2 className="text-carnation">Forum Discussion</h2>
              </div>
            </div> */}
          </div>

          {posts.map((posts, index) => (
            <section className="post-details-container" key={index}>
              <div className="container p-t-50">
                <div className="card ">
                  <div className="card-body">
                    <div className="col-lg-12 col-md-6 col-sm-6">
                      <h4>{posts.title}</h4>
                      <div className="row">
                        <div className="post-tags">
                          {Array.isArray(posts.tags) ? (
                            posts.tags.map((tag, key) => (
                              <span
                                className="mar-r-8 badge badge-pill badge-primary"
                                key={key}
                              >
                                {tag}
                              </span>
                            ))
                          ) : (
                            <span className="mar-r-8 badge badge-pill badge-primary">
                              {posts.tags}
                            </span>
                          )}
                        </div>
                      </div>
                      <hr></hr>
                      {posts.student_doc.map((item, index) => (
                        <div className="post-details-content1">
                          <div className="author-image">
                            <img src={item.profile_pic} alt="user" />
                            <p>
                              {item.first_name} {item.last_name}
                            </p>
                          </div>
                          <span className="post-author"></span>
                          <p className="event-title no-mb small">
                            {moment(parseInt(posts.timestamp)).format("lll")}{" "}
                          </p>
                        </div>
                      ))}
                      <div>
                        <p className="post-body">{posts.body}</p>
                      </div>
                      <hr></hr>
                      {posts.comment_docs.length === 0 ? (
                        <h5>
                          There are no comments on this post, add your first
                          comment!
                        </h5>
                      ) : (
                        <div>
                          <h4>Comments</h4>
                          {posts.comment_docs.map((comment_docs, key) => (
                            <div className="forum-comment-card" key={key}>
                              {comment_docs.student_doc.map((item, index) => (
                                <div className="post-details-content1">
                                  <div className="author-image">
                                    <img src={item.profile_pic} alt="user" />
                                    <p>
                                      {item.first_name} {item.last_name}
                                    </p>
                                  </div>
                                  <span className="post-author"></span>
                                  <p className="event-title no-mb small">
                                    {moment(
                                      parseInt(comment_docs.timestamp)
                                    ).format("lll")}{" "}
                                  </p>
                                </div>
                              ))}

                              <div>
                                <p className="post-body">{comment_docs.body}</p>
                              </div>
                              <hr></hr>
                            </div>
                          ))}
                        </div>
                      )}
                    </div>
                    <div className="col-lg-12 col-md-12 col-sm-12">
                      {/* <hr></hr> */}
                    </div>
                    <CommentCard />
                  </div>
                </div>
              </div>
            </section>
          ))}
        </div>
        <Footer />
      </div>
    );
  }
}

export default PostDetail;
