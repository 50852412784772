import React from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";

const Box = styled.div`
  border: 3px solid #ff8f4e;
  border-radius: 10px;
`;

const StyledList = styled.ul`
  list-style-type: unset;
  margin-bottom: 0;
`;

const CommunityLink = styled.a`
  overflow-wrap: break-word;
`;

const IndiaAIFestivalHowToParticipate = ({ year }) => {
  const { t } = useTranslation();

  return (
    <>
      <h2 className="mb-0 text-center">{t("how_to_participate_title")}</h2>
      <div className="mt-md-2 row">
        <div className="col-md-4 mt-3">
          <Box className="h-100 w-100 rounded-10 py-4 px-3">
            <h5 className="text-blue text-center">{t("impact_creators")}</h5>
            <StyledList>
              <li className="lead">
                {t("signup_on_platform")}{" "}
                <CommunityLink
                  href="https://aistudent.community/signup"
                  className="text-primary"
                >
                  https://aistudent.community/signup
                </CommunityLink>
              </li>
              <li className="lead">
                {t("click_on_button_to_access")}{" "}
                <strong>
                  {t("india_ai_impact_festival").replace("{year}", year)}
                </strong>{" "}
                {t("to_access_festival_registration_form")}
              </li>
              <li className="lead">{t("fill_in_registration_form")}</li>
              <li className="lead">{t("on_successful_registration")} </li>
              <li className="lead">{t("receive_confirmation_email")}</li>
            </StyledList>
          </Box>
        </div>
        <div className="col-md-4 mt-3">
          <Box className="h-100 w-100 rounded-10 py-4 px-3">
            <h5 className="text-blue text-center">{t("impact_shapers")}</h5>
            <StyledList>
              <li className="lead">
                {t("signup_on_platform_aifc")}{" "}
                <CommunityLink
                  href="https://aifacilitator.community/signup"
                  className="text-primary"
                >
                  https://aifacilitator.community/signup
                </CommunityLink>
              </li>
              <li className="lead">
                {t("click_on_button_to_access")}{" "}
                <strong>
                  {t("india_ai_impact_festival").replace("{year}", year)}
                </strong>{" "}
                {t("to_access_festival_registration_form")}
              </li>
              <li className="lead">{t("fill_in_registration_form")}</li>
              <li className="lead">{t("on_successful_registration")}</li>
              <li className="lead">{t("receive_confirmation_email")}</li>
            </StyledList>
          </Box>
        </div>
        <div className="col-md-4 mt-3">
          <Box className="h-100 w-100 rounded-10 py-4 px-3">
            <h5 className="text-blue text-center">{t("impact_nurturers")}</h5>
            <StyledList>
              <li className="lead">
                {t("signup_on_platform_aifc")}{" "}
                <CommunityLink
                  href="https://aifacilitator.community/signup"
                  className="text-primary"
                >
                  https://aifacilitator.community/signup
                </CommunityLink>
              </li>
              <li className="lead">
                {t("click_on_button_to_access")}{" "}
                <strong>
                  {t("india_ai_impact_festival").replace("{year}", year)}
                </strong>{" "}
                {t("to_access_festival_registration_form")}
              </li>
              <li className="lead">{t("fill_in_registration_form")}</li>
              <li className="lead">{t("on_successful_registration")}</li>
              <li className="lead">{t("receive_confirmation_email")}</li>
            </StyledList>
          </Box>
        </div>
      </div>
    </>
  );
};

export default IndiaAIFestivalHowToParticipate;
