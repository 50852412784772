import React, { Component } from "react";

import Header from "../components/Header/header";
import SecondHeader from "../components/Header/secondheader";
import FooterSection from "../components/Footer";

class CommunityGuidelines extends Component {
  render() {
    return (
      <React.Fragment>
        {localStorage.getItem("jwtToken") == null ? (
          <Header />
        ) : (
          <SecondHeader />
        )}

        <div className="pt-265p pt-md-200p">
          <div className=" blog-container shadow mt-0">
            <main className="container  page-participation-guidelines pb-0">
              <h1 className=" mar-b-30 text-center">Community Guidelines</h1>

              <p>
                AIFC is an online community for facilitators to learn, share
                experiences, and use their knowledge to support students to
                create AI enabled social impact solutions and spread AI
                awareness in an inclusive way. The community is open and free
                for facilitators from across the country to join.
              </p>

              <p>
                To make the community a safe and inclusive space for everyone,
                all are expected to adhere to the following
                <ul className="section-list">
                  <li>Be respectful to others in the community</li>
                  <li>Be direct and communicate in a proper manner</li>
                  <li>
                    Be inclusive of members of all gender, nationality, caste,
                    etc.
                  </li>
                  <li>
                    Understand different perspectives while having a discourse
                    within the community
                  </li>
                  <li>
                    Appreciate and accommodate each other’s similarities and
                    differences
                  </li>
                  <li>Lead by example</li>
                </ul>
              </p>

              <p>
                Following behaviours will not be accepted and will invite
                disciplinary action -
                <ul className="section-list">
                  <li>
                    Violence and threats of violence against individuals or
                    groups
                  </li>
                  <li>Personal attacks against members inside the community</li>
                  <li>
                    Derogatory language towards anyone, in any conversation
                  </li>
                  <li>Being disrespectful to others in the community</li>
                  <li>
                    Discriminatory behaviour towards members in terms of gender,
                    nationality, caste, etc.
                  </li>
                  <li>
                    Influencing unacceptable behaviour to instigate a reaction
                  </li>
                </ul>
              </p>

              <p>
                In case of disobedience of guidelines, necessary action will be
                taken, which includes but is not limited to dismissal of
                membership.
              </p>

              <h2 className="left-blue-border mar-b-30 text-left mt-4">
                Reporting
              </h2>

              <p>
                Reports of harassment/discrimination will be promptly and
                thoroughly investigated and appropriate measures will be taken
                to address the situation.
              </p>

              <p>
                False reporting, especially to retaliate or exclude, will not be
                accepted or tolerated.
              </p>

              <p>
                In addition, any participant who is found to abuse the reporting
                process will be considered to be in violation of these
                guidelines and invite disciplinary action.
              </p>
            </main>
          </div>
        </div>
        <FooterSection noSubscription={true} />
      </React.Fragment>
    );
  }
}

export default CommunityGuidelines;
