import React from "react";
import styled from "styled-components";

const Container = styled.div`
  border: 3px solid #ff8f4e;
  border-radius: 10px;
`;

const ImageContainer = styled.div`
  width: 45px;
  height: 45px;
  display: none;
`;

const StyledList = styled.ul`
  list-style-type: disc;
`;

const IndiaAIFestivalMainAwards = ({ title, data }) => {
  return (
    <div>
      <h2 className="text-center">{title}</h2>
      <Container>
        {data.categories.map((category) => (
          <div className="w-100 rounded-10 py-3 px-3">
            <div className="d-flex mb-3">
              <div className="d-flex align-items-center w-100">
                <h5 className="text-blue flex-grow-1 mb-0 text-center">
                  {category.title}
                </h5>
                <ImageContainer>
                  <img src={category.imageUrl} className="w-100" />
                </ImageContainer>
              </div>
            </div>
            <StyledList className="pl-4">
              {category.items.map((item) => (
                <li className="mb-2">{item.description}</li>
              ))}
            </StyledList>
          </div>
        ))}
      </Container>
    </div>
  );
};

export default IndiaAIFestivalMainAwards;
