import React, { Component } from "react";

import Header from "../Header/header";
import SecondHeader from "../Header/secondheader";
import Footer from "../Footer/index";
import Platform from "../../static/img/providePlatform.png";
import Knowledge from "../../static/img/leverageKnowledge.png";
import Awareness from "../../static/img/spreadAwareness.png";

class AboutUs extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    return (
      <React.Fragment>
        {localStorage.getItem("jwtToken") == null ? (
          <Header />
        ) : (
          <SecondHeader />
        )}

        <section className="our-blog-section  gray-light-bg">
          <div className=" blog-container shadow">
            <div className="container">
              <div className="row user-info-container">
                <div className="col-md-12">
                  <h1 className="mar-b-30 text-center">
                    About - AI Facilitator Community
                  </h1>
                  <p className="mar-b-30 text-left lead">
                    AI Facilitator Community (AIFC) is a collaborative
                    initiative by the Central Board of Secondary Education
                    (CBSE), the apex body under the Central Government’s
                    Ministry of Education, and Intel® India.
                  </p>
                </div>
                <div className="d-flex align-items-center flex-column flex-md-row mb-3 mb-md-5">
                  <div className="offset-md-1 col-md-3">
                    <img
                      src="img/about-us.png"
                      alt="img"
                      style={{ width: "inherit" }}
                    />
                  </div>
                  <div className="col-md-7">
                    <p className="mb-2 text-justify lead">
                      AI Facilitator Community (AIFC) has been envisaged to
                      empower educators/facilitators, irrespective of their
                      fields and expertise. It has been designed as a
                      facilitator-driven community that promotes collaborative
                      learning amongst facilitators around AI. Such empowered
                      educators shall further facilitate their students to
                      harness the power of AI as an enabler, towards building a
                      digital-first innovative next generation. Moreover, this
                      platform shall serve as hub for sharing of ideas between
                      educators, in order to support, guide and empower students
                      in creating AI-driven real-life social impact solutions.
                    </p>
                  </div>
                </div>
              </div>

              <div className="mb-3 mb-md-5">
                <h2 className=" mar-b-30 text-center mt-4">
                  The AI Facilitator Community (AIFC) aims to
                </h2>
                <div>
                  <div className="row py-4">
                    <div className="px-5 col-md-4 text-center">
                      <img
                        src={Platform}
                        alt=""
                        className="hero-icon-landing"
                      />
                      <h5 className="lead">
                        Provide a platform for facilitators to come together to
                        interact, learn and share experiences
                      </h5>
                    </div>
                    <div className="px-5 col-md-4 text-center">
                      <img
                        src={Knowledge}
                        alt=""
                        className="hero-icon-landing"
                      />

                      <h5 className="lead">
                        Leverage their Knowledge to create AI-enabled lesson
                        plans and facilitate students to develop social impact
                        solutions
                      </h5>
                    </div>
                    <div className="px-5 col-md-4 text-center">
                      <img
                        src={Awareness}
                        alt=""
                        className="hero-icon-landing"
                      />

                      <h5 className="lead">
                        Spread AI awareness in an inclusive way
                      </h5>
                    </div>
                  </div>
                </div>
              </div>
              <div className="mb-3 mb-md-5">
                <h2 className="mar-b-30 text-center mt-4">
                  Why join the AIFC?
                </h2>
                <div className="row py-4">
                  <div className="p-3 col-md-4 text-center">
                    <div className="border border-theme-stone h-100 w-100 rounded-10 py-4 px-3 d-flex align-items-center justify-content-center">
                      <div>
                        <h5 className="text-carnation">
                          Connect and Collaborate
                        </h5>
                        <p className="lead">
                          with like-minded people from teaching fraternity
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="p-3 col-md-4 text-center">
                    <div className="border border-theme-stone h-100 w-100 rounded-10 py-4 px-3 d-flex align-items-center justify-content-center">
                      <div>
                        <h5 className="text-carnation">Learn AI</h5>
                        <p className="lead">
                          as a skill through webinars with Intel® AI certified
                          coaches and experts
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="p-3 col-md-4 text-center">
                    <div className="border border-theme-stone h-100 w-100 rounded-10 py-4 px-3 d-flex align-items-center justify-content-center">
                      <div>
                        <h5 className="text-carnation">
                          Get Access to the right resources
                        </h5>
                        <p className="lead">curated from across the world</p>
                      </div>
                    </div>
                  </div>
                  <div className="p-3 col-md-4 text-center">
                    <div className="border border-theme-stone h-100 w-100 rounded-10 py-4 px-3 d-flex align-items-center justify-content-center">
                      <div>
                        <h5 className="text-carnation">
                          Participate in Online challenges
                        </h5>
                        <p className="lead">
                          to test your AI knowledge and up-level yourself
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="p-3 col-md-4 text-center">
                    <div className="border border-theme-stone h-100 w-100 rounded-10 py-4 px-3 d-flex align-items-center justify-content-center">
                      <div>
                        <h5 className="text-carnation">
                          Discuss, Deliberate & Share
                        </h5>
                        <p className="lead">
                          on relevant AI topics and ideas to integrate AI in
                          curriculum through Blogs & Forum section
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="p-3 col-md-4 text-center">
                    <div className="border border-theme-stone h-100 w-100 rounded-10 py-4 px-3 d-flex align-items-center justify-content-center">
                      <div>
                        <h5 className="text-carnation">
                          Facilitate students to build social impact solutions
                        </h5>
                        <p className="lead">
                          Gather ideas to facilitate students to develop social
                          impact solutions
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row mb-3 mb-md-5">
                <div className="col-md-12 text-center">
                  <h2 className="mar-b-30 text-center">
                    Who can join the AIFC?
                  </h2>
                </div>
                <div className="col-md-12 text-center">
                  <p className="mar-b-30 text-left lead">
                    The community will be open for facilitators from CBSE and
                    non-CBSE Schools
                  </p>
                </div>
              </div>
              <div className="join-list">
                <h2 className="mar-b-30 text-center">How to join AIFC?</h2>
                <span className="lead text-black">
                  1. Click{" "}
                  <a
                    style={{ color: "#0056b3" }}
                    href="http://www.aifacilitator.community"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="underline-link"
                  >
                    here to
                  </a>{" "}
                  visit the AI Facilitator Community Home page
                </span>
                <br />
                <span className="lead text-black">
                  2. Click on ‘
                  <a
                    style={{ color: "#0056b3" }}
                    href="https://aifacilitator.community/signup"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="underline-link"
                  >
                    Sign Up
                  </a>
                  ’; fill in the required details and submit
                </span>
                <br />
                <span className="lead text-black">
                  3. A verification email will be sent on your registered
                  email-ID
                </span>
                <br />
                <span className="lead text-black">
                  4. Open the email, click on the link provided to verify your
                  email address
                </span>
                <br />
                <span className="lead text-black">
                  5. This will create your profile and confirm your registration
                  as a member of the AIFC
                </span>
                <br />
                <span className="lead text-black">
                  6. You can now Log in anytime using your Email address and
                  Password
                </span>
                <p className="text-center mt-4">
                  To know more, download the information manual{" "}
                  <a
                    style={{ color: "#0056b3" }}
                    href="assets/pdf/information-manual.pdf"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="underline-link"
                  >
                    here
                  </a>
                </p>
              </div>
            </div>
          </div>
        </section>
        <Footer />
      </React.Fragment>
    );
  }
}

export default AboutUs;
