import React, { Component } from "react";
import { withRouter } from "react-router-dom";

class StartLearning extends Component {
  constructor(props) {
    super(props);

    this.state = {
      data: [],
    };
  }

  componentDidMount() {
    this.getData();
  }

  getData() {
    const id = localStorage.getItem("studentId");
    const token = localStorage.getItem("jwtToken");
    fetch(`${process.env.REACT_APP_API_URL}/dashboard/` + id, {
      headers: {
        "x-access-token": token,
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    })
      .then((response) => response.json())
      .then(
        (result) => {
          this.setState({
            isLoaded: true,
            data: result.data,
          });
        },
        (error) => {
          this.setState({
            isLoaded: true,
            error,
          });
        }
      );
  }

  render() {
    const { data } = this.state;
    return (
      <div>
        {data.map((data, index) => (
          <div key={index}>
            {data.startProgress === false ? (
              <div className="start-learning px-5 py-4 m-3 mt-4">
                <div className="row">
                  <h2 className="mb-3">
                    {" "}
                    {data.all_courses_complete
                      ? "COMPLETED"
                      : "CONTINUE LEARNING"}
                  </h2>
                </div>

                <div className="row">
                  {data.all_courses_complete ? (
                    <>All courses are completed</>
                  ) : (
                    <>
                      <div className="col-md-6 no-padding image-container text-center">
                        <img
                          className="learning-hero-img cursor-pointer"
                          src={data.next_course && data.next_course.cover}
                          alt="mainimg"
                          onClick={() =>
                            this.props.history.push(
                              `single_course/${data.next_course.order}`
                            )
                          }
                        />
                      </div>

                      <div className="col-md-6 text-left ">
                        <h4
                          className="cursor-pointer"
                          onClick={() =>
                            this.props.history.push(
                              `single_course/${data.next_course.order}`
                            )
                          }
                        >
                          {data.next_course && data.next_course.title}
                        </h4>
                        <p className="lesson-desc text-left">
                          {data.next_course && data.next_course.desc}
                        </p>
                        {data.upcoming_course_label && (
                          <h4>
                            Up Next:
                            <span
                              className="light cursor-pointer"
                              onClick={() =>
                                this.props.history.push(
                                  `single_course/${data.upcoming_course_order}`
                                )
                              }
                            >
                              {" " + data.upcoming_course_label}
                            </span>
                          </h4>
                        )}
                      </div>
                    </>
                  )}
                </div>
              </div>
            ) : (
              <div className="start-learning p-4 m-3 mt-4">
                <h2
                  className="mb-0 cursor-pointer"
                  onClick={() => {
                    this.props.history.push(`letslearn`);
                  }}
                >
                  START LEARNING
                </h2>
                <p>Start your journey with learning resources</p>
                <div className="row">
                  <div className="col-md-5">
                    <div
                      style={{
                        height: "300px",
                        borderRadius: "10px",
                        background: `url("https://aifacilitator.community.s3.amazonaws.com/1591096093051.jpg")`,
                        backgroundSize: "cover",
                      }}
                      width="100%"
                    />
                  </div>
                  <div className="col-md-7">
                    AI Facilitator Community (AIFC) is a collaborative
                    initiative by the Central Board of Secondary Education
                    (CBSE), the apex body under the Central Government’s
                    Ministry of Human Resource Development and Intel India. AIFC
                    is an online community for students from all across the
                    country where they may learn, share experiences, create AI
                    enabled social impact solutions and spread AI awareness in
                    an inclusive way. The aim is to help bridge the digital
                    divide, unlock the true potential of AI for society, and
                    build an AI-ready generation.
                  </div>
                </div>
              </div>
            )}
          </div>
        ))}
      </div>
    );
  }
}

export default withRouter(StartLearning);
