import React, { Component, Fragment } from "react";
import SliderReact from "./Slider";
import DOMPurify from "dompurify";

class WhatsNew extends Component {
  constructor(props) {
    super(props);

    this.state = {
      data: null,
    };
  }

  getData() {
    const token = localStorage.getItem("jwtToken");
    fetch(`${process.env.REACT_APP_API_URL}/whatsNew`, {
      headers: {
        "x-access-token": token,
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    })
      .then((response) => response.json())
      .then(
        (result) => {
          this.setState({
            data: result.data,
          });
        },
        (error) => {
          this.setState({
            isLoaded: true,
            error,
          });
        }
      );
  }

  componentDidMount() {
    this.getData();
  }

  renderDescription(description) {
    return (
      <div
        className="ws-pw"
        dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(description) }}
      />
    );
  }

  renderImgTxtLinkCard(card, cardIndex) {
    return (
      <a
        href={card.link}
        key={cardIndex}
        className="card mb-3 d-flex border-0 card-dashboard h-100"
        rel="noopener noreferrer"
      >
        <div
          className="rounded-10"
          style={{
            height: "150px",
            backgroundImage: `url(${card.cover})`,
            backgroundPosition: "center",
            backgroundSize: "contain",
            backgroundRepeat: "no-repeat",
          }}
        />
        <div className="px-3 pt-3">
          <p className="card-text">
            {this.renderDescription(card.description.substring(0, 200))}
          </p>
        </div>
        <div className="flex-shrink-0 d-flex justify-content-end mt-auto flex-column">
          <span
            className="btn btn-crimson text-center text-white py-2 d-block"
            style={{ borderRadius: "0px 0px 5px 5px" }}
          >
            Explore
          </span>
        </div>
      </a>
    );
  }

  renderImgTxtCard(card, cardIndex) {
    return (
      <a
        href={card.link}
        key={cardIndex}
        rel="noopener noreferrer"
        className="bg-white rounded-10 d-flex p-3 mb-3 border-0 h-48p"
      >
        <div
          style={{
            width: "40%",
            height: "150px",
            backgroundImage: `url(${card.cover})`,
            backgroundPosition: "center",
            backgroundSize: "contain",
            backgroundRepeat: "no-repeat",
          }}
        />
        <p className="pl-2 my-auto" style={{ width: "60%" }}>
          {this.renderDescription(card.description.substring(0, 140))}
        </p>
      </a>
    );
  }

  renderImgCard(card, cardIndex) {
    return (
      <a
        href={card.link}
        rel="noopener noreferrer"
        key={cardIndex}
        className="card mb-3 rounded-10 border-0 h-48p"
        style={{
          width: "100%",
          height: "150px",
          backgroundImage: `url(${card.cover})`,
          backgroundPosition: "center",
          backgroundSize: "contain",
          backgroundRepeat: "no-repeat",
        }}
      />
    );
  }

  renderCard(card, cardIndex) {
    switch (card.card_type) {
      case "img-txt-link":
        return this.renderImgTxtLinkCard(card, cardIndex);
      case "image-text":
        return this.renderImgTxtCard(card, cardIndex);
      case "image":
      default:
        return this.renderImgCard(card, cardIndex);
    }
  }

  getSlides() {
    let cards = this.state.data ? [...this.state.data] : [],
      slides = [],
      columns = [],
      columnIndex = 0;

    while (cards.length) {
      let card = cards.shift();
      columns[columnIndex] = columns[columnIndex] ? columns[columnIndex] : [];

      if (columns[columnIndex].length === 0) {
        columns[columnIndex].push(card);
      } else if (columns[columnIndex].length === 1) {
        if (card.card_type === "img-txt-link") {
          columnIndex++;
          columns[columnIndex] = [];
        }
        columns[columnIndex].push(card);
      } else {
        columnIndex++;
        columns[columnIndex] = [];
        columns[columnIndex].push(card);
      }

      if (card.card_type === "img-txt-link") {
        columnIndex++;
      }
    }

    for (let i = 0; i < columns.length; i++) {
      let slideIndex = Math.floor(i / 3);
      slides[slideIndex] = slides[slideIndex] ? slides[slideIndex] : [];
      slides[slideIndex].push(columns[i]);
    }

    return slides;
  }

  render() {
    let slides = this.getSlides();

    return (
      <>
        {slides.length > 0 ? (
          <div className="whats-new p-2 p-md-4 m-3">
            <h2 className="my-2">WHAT'S NEW?</h2>
            <SliderReact
              slidesToShow={1}
              variableWidth={false}
              hoverColor={true}
            >
              {slides.map((slide, slideIndex) => (
                <div key={slideIndex}>
                  <div className="d-flex flex-wrap" style={{ height: "360px" }}>
                    {slide.map((columns, columnIndex) => (
                      <div
                        key={columnIndex}
                        className="h-100 px-2 slide fz-12 fz-xl-14"
                      >
                        {columns.map((card, cardIndex) =>
                          this.renderCard(card, cardIndex)
                        )}
                      </div>
                    ))}
                  </div>
                </div>
              ))}
            </SliderReact>
          </div>
        ) : null}
      </>
    );
  }
}

export default WhatsNew;
