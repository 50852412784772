import React from "react";

class Faq extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    /**
     * Your ajax will goes here to get data then call setState
     */
  }

  render() {
    const listItem = (id, title, description, lastItem, accordionName) => {
      const additionalClass = lastItem ? "last-accordion-section " : null;
      return (
        <div
          className={`c-pointer  ${additionalClass} card border-0 border-bottom-solid  rounded-0`}
          key={id}
          id={`accordian${id}`}
        >
          <div
            className="card-header mb-0 faq-list-hover p-0 border-0 bg-white  hover-text-theme-primary"
            role="tab"
            id="heading1"
          >
            <div
              className="collapsed text-decoration-none py-4 border-bottom  text-theme-black-pearl hover-text-theme-primary d-block py-8"
              data-toggle="collapse"
              data-parent={`#accordion${accordionName}`}
              href={"#collapse" + id}
              aria-expanded="false"
              aria-controls={"collapse" + id}
            >
              <h5 className="mb-0 plus-icon pl-3" style={{ color: "#7288a2" }}>
                {title}
              </h5>
            </div>
          </div>
          <div
            id={"collapse" + id}
            className="collapse"
            role="tabpanel"
            aria-labelledby={"heading" + id}
            data-parent={`#accordion${accordionName}`}
          >
            <div className="card-body lead pt-3 text-dark border-bottom">
              {description}
            </div>
          </div>
        </div>
      );
    };

    const aboutPlatform = [
      {
        title: "What is the AI Facilitator Community?",
        description: (
          <p class="text-justify">
            AI Facilitator Community (AIFC) is an online community connecting
            facilitators across the country. A facilitator-driven platform, it
            offers opportunities to the facilitators/educators/teachers for
            collaborating, sharing best practices, professional development.
            Facilitators can further utilise their knowledge in facilitating and
            empowering their students to create AI-enabled social impact
            solutions and democratizing AI awareness.
          </p>
        ),
      },
      {
        title: "Who can be a part of AIFC?",
        description:
          "Any facilitator from anywhere in the country from any kind of school - CBSE/ non-CBSE can join the AIFC.",
      },
      {
        title: "How can I register?",
        description: (
          <>
            You can register for the community by clicking{" "}
            <a
              style={{ color: "#0056b3" }}
              href="https://aistudent.community/signup"
              target="_blank"
              rel="noopener noreferrer"
            >
              here
            </a>
            . Once you fill in your details and submit, you will receive a
            verification email on your registered email-ID. Click on the link
            provided in the mail to verify your email address. This will
            complete the registration process and create a member profile.
          </>
        ),
      },
      {
        title:
          "I did not receive the verification mail after registration. What do I do?",
        description:
          "Check your Spam folder to see if you’ve received an email there. If not, click on the ‘Resend’ icon to receive an email on your registered email ID again.",
      },
      {
        title: "Which social media platforms are connected to this platform?",
        description: (
          <>
            You can follow us on the following social media platforms:
            <ul style={{ listStyleType: "disc" }} className="ml-4">
              <li>
                Facebook:{" "}
                <a
                  style={{ color: "#0056b3" }}
                  href="https://www.facebook.com/AI4Youth/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  @AI4Youth
                </a>
              </li>
              <li>
                Twitter:{" "}
                <a
                  style={{ color: "#0056b3" }}
                  href="https://twitter.com/Ai4Youth"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  @ai4Youth
                </a>
              </li>
              <li>
                Instagram:{" "}
                <a
                  style={{ color: "#0056b3" }}
                  href="https://www.secure.instagram.com/ai4.youth/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  @ai4.youth
                </a>
              </li>
              <li>
                or use <span className="font-weight-bold">#AI4Youth</span> for
                more updates
              </li>
            </ul>
          </>
        ),
      },
    ];

    const letLearnSection = [
      {
        title: "How can I keep a track of my learning journey?",
        description:
          "Your dashboard gives you an insight into your own learning journey.",
      },
      {
        title:
          "How do I share my attempt on the tasks given in the ‘Let’s Learn’ section?",
        description:
          "You can click on the Share button after you’ve completed a module or a challenge.",
      },
      {
        title:
          "I have gone through all the learning resources, what’s the next step?",
        description:
          "You may attend webinars, events, help other facilitators and participate in various challenges hosted on the platform.",
      },
    ];

    const webinarAndEvents = [
      {
        title:
          "How do I get notified about any upcoming webinar or other events?",
        description:
          "Navigate to the Events page anytime to check what’s happening on the AI Facilitator Community.",
      },
      {
        title: "How do I register for any upcoming events?",
        description:
          "Navigate to the Events tab and click on the Event(s) you want to be a part of. Once you reach the Event page, click on ‘RSVP’ to register.",
      },
      {
        title: "Which platform would be used for webinars? How do I use that?",
        description: (
          <>
            Cisco WebEx is used for webinars. You can download it from{" "}
            <a
              href="https://www.webex.com/downloads.html"
              target="_blank"
              style={{ color: "#0056b3" }}
              rel="noopener noreferrer"
            >
              here
            </a>
            .
          </>
        ),
      },
    ];

    const forumAndBlog = [
      {
        title: "How do I write my own blog?",
        description:
          "You can navigate to Blogs from the Header Menu and click on the “Add New Blog” button to start writing your own blog. Once you’re done, you can submit the blog to the Community team. Our team will review your blog, suggest changes (if required) and publish the final attempt on the platform!",
      },
      {
        title: "How do I initiate a new thread on the forum?",
        description:
          "Navigate to the group that you want to create a topic in and click on the “+ New Topic” button on the top-right of the Forum to initiate a new thread.",
      },
    ];
    return (
      <React.Fragment>
        <section className="our-blog-section  gray-light-bg">
          <div className=" blog-container shadow">
            <div className="row user-info-container">
              <div className="col-md-12">
                <h1 className=" mar-b-30 text-center">
                  Frequently Asked Questions
                </h1>
              </div>
            </div>

            <div className="container w-75 pb-3">
              <h2 className=" mar-b-30 text-center ">About the platform</h2>
              <div
                className="accordion md-accordion mt-6 top-accordian "
                id="accordionAboutPlatform"
                role="tablist"
                aria-multiselectable="true"
              >
                {aboutPlatform.map((item, index) =>
                  listItem(
                    index + 20,
                    item.title,
                    item.description,
                    aboutPlatform.length - 1 === index,
                    "AboutPlatform"
                  )
                )}
              </div>
            </div>

            <div className="container w-75 py-4 ">
              <h2 className=" mar-b-30 text-center">Let’s Learn Section</h2>
              <div
                className="accordion md-accordion mt-6 top-accordian "
                id="accordionLetsLearn"
                role="tablist"
                aria-multiselectable="true"
              >
                {letLearnSection.map((item, index) =>
                  listItem(
                    index + 40,
                    item.title,
                    item.description,
                    letLearnSection.length - 1 === index,
                    "LetsLearn"
                  )
                )}
              </div>
            </div>

            <div className="container w-75 py-4 ">
              <h2 className=" mar-b-30 text-center">Webinars & Events</h2>
              <div
                className="accordion md-accordion mt-6 top-accordian "
                id="accordionWebinarAndEvents"
                role="tablist"
                aria-multiselectable="true"
              >
                {webinarAndEvents.map((item, index) =>
                  listItem(
                    index + 60,
                    item.title,
                    item.description,
                    webinarAndEvents.length - 1 === index,
                    "WebinarAndEvents"
                  )
                )}
              </div>
            </div>

            <div className="container w-75  py-4 ">
              <h2 className="mar-b-30 text-center">Forum & Blog</h2>
              <div
                className="accordion md-accordion mt-6 top-accordian "
                id="accordionForumAndBlog"
                role="tablist"
                aria-multiselectable="true"
              >
                {forumAndBlog.map((item, index) =>
                  listItem(
                    index + 100,
                    item.title,
                    item.description,
                    forumAndBlog.length - 1 === index,
                    "ForumAndBlog"
                  )
                )}
              </div>
            </div>
          </div>
        </section>
      </React.Fragment>
    );
  }
}

export default Faq;
