/* eslint-disable default-case */
import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import SecondHeader from "../components/Header/secondheader";
import Header from "../components/Header/header";
import Footer from "../components/Footer/index";
import Select from "react-select";
import moment from "moment";
import "react-notifications/lib/notifications.css";
import PDFViewer from "../components/Certificates/PDFViewer";
import {
  NotificationContainer,
  NotificationManager,
} from "react-notifications";

class GenerateCertificate extends Component {
  state = {
    certificateUrl: null,
    error: "",
    exceptions: {},
    isFetching: false,
    showReportModal: false,
    showConfirmationModal: false,
    eventList: [],
    selectedEvent: null,
    participant: null,
    isDownloaded: false,
    isLoggedIn: true,
  };

  componentDidMount() {
    this.getEventsList();
  }

  getEventsList = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/certificate/events`,
        {
          headers: this.getFetchHeaders(),
        }
      );

      if (response.status === 401) {
        this.setState({ isLoggedIn: false });
        return;
      }

      const result = await response.json();
      const selectedEvent = result[0]
        ? { ...result[0], label: result[0].name, value: result[0]._id }
        : null;
      this.setState({ eventList: result, selectedEvent }, () => {
        if (selectedEvent) {
          this.getCertificate(selectedEvent);
        }
      });
    } catch (error) {
      console.error("Error fetching certificate list:", error);
    }
  };

  getFetchHeaders = () => ({
    "x-access-token": localStorage.getItem("jwtToken"),
    Accept: "application/json",
    "Content-Type": "application/json",
  });

  createNotification = (type, successMessage) => {
    const notifications = {
      info: () => NotificationManager.info("Info message"),
      success: () => NotificationManager.success(successMessage, "Success"),
      warning: () =>
        NotificationManager.warning(
          "Warning message",
          "Close after 3000ms",
          3000
        ),
      error: () =>
        NotificationManager.error("Error message", "Click me!", 5000, () =>
          alert("callback")
        ),
    };

    if (notifications[type]) {
      notifications[type]();
    } else {
      console.warn(`Notification type "${type}" is not recognized.`);
    }
  };

  onSubmit = async () => {
    const { error, selectedEvent } = this.state;
    const exceptions = {};

    if (!error) {
      exceptions.error = "Error cannot be empty";
    }

    if (Object.keys(exceptions).length !== 0) {
      this.setState({ exceptions });
      return;
    }

    const successMessage =
      "Request has been registered, Changes will be implemented soon";

    try {
      await fetch(`${process.env.REACT_APP_API_URL}/certificate/report-error`, {
        method: "POST",
        headers: this.getFetchHeaders(),
        body: JSON.stringify({
          error,
          metaId: selectedEvent._id,
        }),
      });
      this.createNotification("success", successMessage);
    } catch (error) {
      console.error("Error reporting:", error);
    }

    this.setState({ showReportModal: false });
  };

  onConfirm = async () => {
    const { selectedEvent } = this.state;

    this.setState({ showConfirmationModal: false });
    const successMessage = `Your certificate has been sent at ${this.state.participant.participant_email}.`;

    try {
      await fetch(`${process.env.REACT_APP_API_URL}/certificate/send`, {
        method: "POST",
        headers: this.getFetchHeaders(),
        body: JSON.stringify({
          metaId: selectedEvent._id,
          term: selectedEvent.term,
        }),
      });
      this.createNotification("success", successMessage);
    } catch (error) {
      console.error("Error sending certificate:", error);
    }
    this.setState({ isDownloaded: true });
  };

  handleEventFilter = (selectedEvent) => {
    const params = this.state.eventList?.find(
      (event) => event._id === selectedEvent.value
    );

    if (params) {
      this.setState({
        selectedEvent: { ...selectedEvent, ...params },
      });
      this.getCertificate(params);
    }
  };

  getCertificate = async (params) => {
    this.setState({ isFetching: true });
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/certificate`,
        {
          method: "POST",
          headers: this.getFetchHeaders(),
          body: JSON.stringify({ term: params.term, metaId: params._id }),
        }
      );
      const data = await response.json();
      this.setState({
        certificateUrl: data.fileUrl,
        participant: data.participant,
        isDownloaded: data.participant.downloaded,
        isFetching: false,
      });
    } catch (error) {
      console.error("Error generating certificate:", error);
      this.setState({ isFetching: false });
    }
  };

  onChange = (e) => {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  };

  toggleModal = (modal) => {
    this.setState((prevState) => ({ [modal]: !prevState[modal] }));
  };

  renderModal = (modalName, title, body, onConfirm) => (
    <>
      {this.state[modalName] && (
        <div
          className="modal-backdrop fade show"
          onClick={() => this.toggleModal(modalName)}
        ></div>
      )}
      <div
        className={`${this.state[modalName] ? "show" : ""}`}
        onClick={() => this.toggleModal(modalName)}
      >
        <div
          className="modal fade show"
          style={{ display: this.state[modalName] ? "block" : "none" }}
          role="dialog"
          onClick={(e) => e.stopPropagation()}
        >
          <div
            className="modal-dialog"
            style={{ boxShadow: "0 5px 15px rgba(0, 0, 0, 0.5)" }}
            role="document"
          >
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title">{title}</h5>
                <button
                  type="button"
                  className="close"
                  onClick={() => this.toggleModal(modalName)}
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div className="modal-body">{body}</div>
              <div className="modal-footer">
                <button
                  onClick={onConfirm}
                  type="button"
                  className="btn btn-primary"
                >
                  Confirm
                </button>
                <button
                  type="button"
                  className="btn btn-secondary"
                  onClick={() => this.toggleModal(modalName)}
                >
                  Close
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );

  render() {
    const options = this.state.eventList?.map((event) => ({
      label: event.name,
      value: event._id,
    }));

    return (
      <React.Fragment>
        {this.state.isLoggedIn ? (
          <>
            <SecondHeader />
            <section className="our-blog-section gray-light-bg single-blog-container">
              <div className="d-flex justify-content-end">
                <div className="col-3 mr-5" style={{ zIndex: 2 }}>
                  <Select
                    placeholder="Select The Event"
                    className="form-select"
                    classNamePrefix="react-select"
                    value={this.state.selectedEvent}
                    onChange={this.handleEventFilter}
                    options={options}
                  />
                </div>
              </div>
              <div className="blog-container container mx-auto shadow rounded">
                <div className="row">
                  <div
                    className="col-md-12"
                    style={{ height: this.state.certificateUrl ? "" : "100px" }}
                  >
                    {!this.state.certificateUrl && !this.state.isFetching ? (
                      <div className="text-center">
                        <h3>
                          No certificate found for{" "}
                          <strong>{this.state.selectedEvent?.label}</strong>.
                          Please select another event.
                        </h3>
                      </div>
                    ) : this.state.isFetching ? (
                      <div className="loading-wrapper">Loading...</div>
                    ) : (
                      <div>
                        <div className="section-heading text-center">
                          <h2>Participation Certificate</h2>
                          <p className="sub-heading">
                            Congratulations on completing the CBP!
                          </p>
                        </div>
                        {!this.state.isDownloaded ? (
                          <>
                            <div className="d-flex justify-content-between mt-5">
                              <button
                                className="btn-blue mar-b-20"
                                onClick={() =>
                                  this.toggleModal("showConfirmationModal")
                                }
                              >
                                Get Certificate On Email
                              </button>
                              <button
                                className="btn-blue mar-b-20"
                                onClick={() =>
                                  this.toggleModal("showReportModal")
                                }
                              >
                                Correct My Certificate
                              </button>
                            </div>
                            <div className="mb-3">
                              Expires on{" "}
                              <strong className="text text-danger">
                                {moment(
                                  this.state.selectedEvent.availableTill
                                ).format("DD-MMMM-YYYY")}
                              </strong>
                            </div>
                          </>
                        ) : (
                          <div className="section-heading text-center text-danger mb-4">
                            You have already downloaded the certificate.
                          </div>
                        )}
                        <PDFViewer dataUrl={this.state.certificateUrl} />
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <NotificationContainer />
            </section>
            <Footer />
          </>
        ) : (
          <>
            <Header />
            <div className="text-center pt-150 px-5">
              <h1 className="pt-5">Please Log In or Sign Up</h1>
              <h4>
                To view your certificate, you need to be logged in. Please log
                in to your account or sign up if you don't have one.
              </h4>
            </div>
          </>
        )}
        {this.renderModal(
          "showReportModal",
          "Submit a Correction for Your Certificate",
          <div className="mar-b-30">
            <div className="form-group">
              <label className="pb-1">Description</label>
              <div className="input-group input-group-merge">
                <textarea
                  onChange={this.onChange}
                  name="error"
                  className="form-control"
                  id="error"
                />
              </div>
              {this.state.exceptions.error && (
                <small className="text-danger">
                  {this.state.exceptions.error}
                </small>
              )}
            </div>
          </div>,
          this.onSubmit
        )}
        {this.renderModal(
          "showConfirmationModal",
          "Confirm Send Certificate On Email",
          <p>
            Please review the details on your certificate carefully before
            downloading. Once downloaded, corrections cannot be made. Ensure all
            information is accurate before proceeding.
          </p>,
          this.onConfirm
        )}
      </React.Fragment>
    );
  }
}

export default withRouter(GenerateCertificate);
