import React, { memo } from "react";
import { Document, Page, pdfjs } from "react-pdf";

const PDFViewer = memo(({ dataUrl }) => {
  pdfjs.GlobalWorkerOptions.workerSrc = `https://unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`;
  return (
    <div className="pdf-container">
      <Document file={dataUrl} onLoadError={console.error}>
        <Page pageNumber={1} />
      </Document>
    </div>
  );
});

export default PDFViewer;
